import React, { useState, useEffect } from "react";
import { json, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Global/Scroll";
import { errorMessages } from "../../../Constants/Errors";
import { product_page_labels } from "../../../Constants/Labels";
import { product_page_message, alert_message_type } from "../../../Constants/Messages";
import { callAPI, useStore, usePage, useUser, isJson } from "../../../Utils/utils";
import { PopUpMessage } from "../../Global/PopupMessage/PopupMessage"
import Alert from "../../Alert/Alert";
import ErrorMessage from "../../Global/ErrorMessage"
import ImageSelector from "../../ImageSelector/ImageSelector";
import ImagesPreview from "../../ImagesPreview/ImagesPreview";
import TextEditor from "../../Global/TextEditor/TextEditor";
import Message from "../../Message/Message";
import './EditProduct.css';
import { Orientations } from "../../../Constants/constants"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from "quill";


export default function EditProduct({ onUpdateProduct }) {

    var { id } = useParams();
    const navigate = useNavigate();
    const { page, setPage } = usePage()
    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const [alert, setAlert] = useState()
    const [mode, setMode] = useState('list')
    const [config, setConfig] = useState();
    const [errors, setErrors] = useState();
    const [payLoad, setPayLoad] = useState();
    const [loading, setLoading] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [storeName, setStoreName] = useState([]);
    const [metaImage, setMetaImage] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [categories, setCategories] = useState(true);
    const [addedImages, setAddedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [featuredImage, setFeaturedImage] = useState();
    const [productSluge, setProductSlug] = useState("");
    const [removedImages, setRemovedImages] = useState([])
    const [savedMetaImages, setSavedMetaImages] = useState([])
    const [defaultCategory, setDefaultCategory] = useState([]);
    const [addedCategories, setAddedCategories] = useState([]);
    const [searchCategoryName, setSearchCategoryName] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [removedCategories, setRemovedCategories] = useState([]);
    const [updatedFormValues, setUpdatedFormValues] = useState("");
    const [savedFeaturedImage, setSavedFeaturedImage] = useState([])
    const [description, setDescription] = useState({})
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openDelectPopup, setOpenDelectPopup] = useState();
    const [content_for_printing, setContentForPrint] = useState()
    const [orientation, setOrientation] = useState(Orientations['portrait'])
    const [short_description,setShortDescription]=useState({})
    // const [page_3_content,SetPage3Content]=useState({})
    const [page_2_content,SetPage2Content]=useState({})
    const [page_content, setPageContent] = useState({ page_3: "This holiday season\n\n[sender_name_on_card]\n\nmade a generous donation in your honor\nto God's love we deliver\n\nWith best wishes for peace, health\nand hope in the new year\n\n[personal_message]" })

    console.log("page_3_content", page_content);
    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    // get data from database based on id on page onload
    useEffect(() => {

        if (id && store?.id) {
            callAPI(`/admin/products/${id}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                }, navigate, setLoading)
                .then(data => {
                    // console.log("getproduct api response " + JSON.stringify(data))
                    if (data.success) {
                        setFormValues(data?.record);
                        const images = data?.record?.images
                        const imageIds = images ? images.map(item => parseInt(item.image_id)) : []

                        setAddedImages(imageIds);
                        setProductSlug(data?.record?.slug?.toLowerCase()?.replace(/\s/g, "-"))
                        setOrientation(data.record?.card_orientation)
                        if (data?.record?.meta?.meta_image &&
                            data?.record?.meta?.image_id &&
                            data?.record?.meta?.path &&
                            data?.record?.meta?.name) {
                            setSavedMetaImages([{
                                "image_id": data?.record?.meta?.image_id,
                                "path": data?.record?.meta?.path,
                                "name": data?.record?.meta?.name
                            }])
                        }
                        if (data.record?.product_type == "PERSONALIZED-CRD") {
                            setContentForPrint(data.record?.content_images)
                            setPageContent(data.record?.content_for_printing)
                        }

                        if (data?.record?.featured_image &&
                            data?.record?.featured_image?.image_id &&
                            data?.record?.featured_image?.path &&
                            data?.record?.featured_image?.name) {
                            setSavedFeaturedImage([{
                                "image_id": data?.record?.featured_image?.image_id,
                                "path": data?.record?.featured_image?.path,
                                "name": data?.record?.featured_image?.name
                            }])
                        }
                    } else {
                        // setErrors(data?.errors)
                        // console.log('errors',data?.errors)
                    }
                }
                )
        }
    }, [id, store?.id])

    console.log("page", page_content, content_for_printing);

    useEffect(() => {
        if (id && store?.id && Object.keys(formValues)?.length != 0) {

            var inital_pay_load = {
                "store_id": store?.id,
                "status": "all",
            }

            var pay_load = payLoad || inital_pay_load
            pay_load.page_size = 100
            pay_load.current_page = 1
            callAPI('/admin/category/by-store',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api from response " + JSON.stringify(data))
                    if (data.success) {
                        setCategories(data.records)

                        if (removedCategories?.length == 0 && defaultCategory?.length == 0 && Object.keys(formValues)?.length != 0) {
                            var addCategory = formValues?.categories?.map((category) =>
                                data?.records?.find((list) => list?.category_id == category?.category_id))
                            // console.log('addCategory',addCategory)
                            setDefaultCategory(addCategory)
                            setSelectedCategories(addCategory)
                            var newLineItems = []
                            //display the items based on the selected products
                            addCategory?.map((selectedCategories) => {
                                if (selectedCategories) {
                                    // Push a new object into newLineItems array
                                    newLineItems.push({
                                        category_id: selectedCategories?.category_id,
                                        store_id: store?.id,
                                        name: selectedCategories?.name
                                    });
                                }
                            })

                            setLineItems(newLineItems)
                        }

                    } else {
                        // console.log(errors,data.errors)
                    }
                }
                )
        }
    }, [id, store?.id, Object.keys(formValues)?.length, payLoad])

    const onChangeField = (event) => {
        const { name, value } = event.target;

        // if (name == 'meta_title') {
        //     // If the field is 'meta_title', remove or replace special characters
        //     const sanitizedValue = value.replace(/[^a-zA-Z0-9\s|—–:…?!"()[\]+&_/.-]/g, '')?.replace(/\//g, '-');
        //     setFormValues({ ...formValues, [name]: sanitizedValue });
        //     setUpdatedFormValues({ ...updatedFormValues, [name]: sanitizedValue });
        // } else {
        // For other fields, simply update the state
        setFormValues({ ...formValues, [name]: value });
        setUpdatedFormValues({ ...updatedFormValues, [name]: value });
        // }

        // Create a new object with the updated field in formValues and updatedFormValues

        setShowErrorMessage(false);
        setErrors([]);
    };
    const onChangeSubdescribtion = (value) => {
        console.log("change",value);
        
        // setFormValues({ ...formValues, short_description: value });
        setUpdatedFormValues({ ...updatedFormValues, short_description: value });
    }
    // console.log(updatedFormValues);
    
    function textEditorChange(name,value){
        console.log("value",value);
        
    }
    // function quillChange(name, value) {
    //     setFormValues({ ...formValues, [name]: value });
    //     setUpdatedFormValues({ ...updatedFormValues, [name]: value });
    // }

    console.log("updated", updatedFormValues);


    // onclick to  validate check require fields and post Storedata database
    const saveProduct = () => {

        // This enables smooth scrolling
        scrollToTop()

        // concat addedCategories and removedCategories 
        var addedCategoriesAndRemovedCategories = addedCategories.concat(removedCategories)

        // check duplicate values
        function findDuplicates(arr) {
            return arr.filter((currentValue, currentIndex) =>
                arr.indexOf(currentValue) !== currentIndex);
        }

        // remove duplicate values in addedCategories and store in newCategories
        var newCategories = addedCategories
        newCategories = newCategories.filter(item => !findDuplicates(addedCategoriesAndRemovedCategories)?.includes(item))

        // remove duplicate values in removedCategories and store in removeDefaultCategories
        var removeDefaultCategories = removedCategories
        removeDefaultCategories = removeDefaultCategories.filter(item => !findDuplicates(addedCategoriesAndRemovedCategories)?.includes(item))

        // Convert and filter null values from addedImages array
        var added_images = addedImages?.map(item => parseInt(item)).filter(item => !isNaN(item));

        // Convert and filter null values from removedImages array
        var removed_images = removedImages?.map(item => parseInt(item)).filter(item => !isNaN(item));

        // Filter out image IDs that are present in both added_images and removed_images arrays
        // var added_images_ids = added_images?.filter(Image => !removed_images?.includes(Image));

        const added_images_ids = added_images?.filter(item => {
            const indexInRemoved = removed_images?.indexOf(item);
            if (indexInRemoved !== -1) {
                removed_images?.splice(indexInRemoved, 1);
                return false;
            }
            return true;
        });

        // Filter out invalid values from added_images_ids array
        var added_product_images = added_images_ids?.filter(item => !Array.isArray(item) || item?.length > 0);

        const added_gallery_images_ids = [...new Set(added_product_images)];

        // If there are no valid added product images, set added_product_images to an empty array
        if (!added_gallery_images_ids) {
            added_gallery_images_ids = [];
        }

        var payload = {
            "product_id": id,
            "store_id": store?.id,
            "name": updatedFormValues?.name?.trim(),
            "sku": updatedFormValues?.sku,
            "product_type": updatedFormValues?.product_type,
            "price": updatedFormValues?.price,
            "status": updatedFormValues?.status,
            "stock": updatedFormValues?.stock,
            "slug": formValues.slug == productSluge ? undefined : productSluge,
            "added_categories": newCategories,
            "removed_categories": removeDefaultCategories,
            "images": { "images": added_gallery_images_ids },
            "card_orientation": updatedFormValues?.card_orientation??orientation,
        }
        if (formValues.product_type == "PERSONALIZED-CRD") {
            let content = {}
            Object.keys(content_for_printing).forEach(each => {
                content[each] = {}
                content[each] = content_for_printing[each]?.image_id
            })
            payload.content_for_printing = content
            payload.content_for_printing.page_2 = page_2_content && Object.keys(page_2_content)?.length ? page_2_content : page_content?.page_2
            payload.content_for_printing.page_3 = page_content?.page_3
        } else {
            payload.content_for_printing = null
        }

        if (featuredImage || featuredImage == null) {

            if (featuredImage == null) {
                payload.featured_image = featuredImage
            }
            else {
                payload.featured_image = featuredImage?.image_id ? featuredImage?.image_id : null
            }
        }

        if (updatedFormValues?.short_description || updatedFormValues?.short_description == ""
            || updatedFormValues?.description || updatedFormValues?.description == ""
            || updatedFormValues?.meta_title || updatedFormValues?.meta_title == ""
            || updatedFormValues?.meta_description || updatedFormValues?.meta_description == "" || Object.keys(description)||Object.keys(short_description)) {
            payload.product_descriptions = {
                "short_description": short_description && Object.keys(short_description)?.length ? short_description : "",
                "description": description && Object.keys(description)?.length ? description : "",
                "meta_title": updatedFormValues?.meta_title,
                "meta_description": updatedFormValues?.meta_description,
            }
        }

        if (payload?.product_descriptions === undefined && metaImage?.image_id) {
            payload.product_descriptions = { "meta_image": metaImage?.image_id }
        }
        else if (payload?.product_descriptions === undefined && metaImage == null) {
            payload.product_descriptions = { "meta_image": metaImage }
        }
        else if (payload?.product_descriptions && metaImage == null) {
            payload.product_descriptions.meta_image = metaImage
        }
        else if (payload?.product_descriptions && metaImage?.image_id) {
            payload.product_descriptions.meta_image = metaImage?.image_id
        }

        console.log("payload", payload)
        callAPI(`/admin/products/update`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(payload)
            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success === true) {
                    onUpdateProduct("success")
                } else {
                    setErrors(data?.errors)
                    setShowErrorMessage(true)
                    const containsEmptyObject = data?.errors?.some(obj => Object.keys(obj)?.length === 0);
                    if (!containsEmptyObject) {
                        onUpdateProduct("error")
                    }
                    // console.log("errors", data?.errors)
                }
            }
            )

    }

    //get the product name during on change of the input value 
    const onSearchCategoryName = (event) => {
        setSearchCategoryName(event.target.value)
        setMode('open')

        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }

        if (event.target.value) {
            pay_load.filters = {
                "conditions": [
                    { "field": "name", "operator": "like", "value": event.target.value, "dataType": "string" }
                ]
            }
        }
        setPayLoad(pay_load)
    }

    const onClickListCatrgory = () => {
        setMode('open')
        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }
        setPayLoad(pay_load)
    }

    //get list of products when on click the search icon
    const onClickCategorySearchIcon = () => {
        setMode('open')

        var pay_load = {
            "store_id": store?.id,
            "status": "all",
        }

        if (searchCategoryName) {
            pay_load.filters = {
                "conditions": [
                    { "field": "name", "operator": "like", "value": searchCategoryName, "dataType": "string" }
                ]
            }
        }

        setPayLoad(pay_load)
    }

    const onChangeFields = (event, category) => {

        if (selectedCategories?.length == 0 && !selectedCategories?.some((selectedCategory) => selectedCategory?.category_id == category?.category_id)) {
            setSelectedCategories([category]);
        }
        else if (selectedCategories?.length > 0 && !selectedCategories?.some((selectedCategory) => selectedCategory?.category_id == category?.category_id)) {
            setSelectedCategories([...selectedCategories, category]);
        }
        else {
            // Remove the unselected product from the selectedCategories list.
            setSelectedCategories(selectedCategories?.filter((selectedCategory) => selectedCategory?.category_id != category?.category_id));
        }

        if (defaultCategory?.some((selectedCategory) => selectedCategory?.category_id == category?.category_id)) {
            setDefaultCategory(defaultCategory?.filter((selectedCategory) => selectedCategory?.category_id != category?.category_id));
            if (removedCategories?.length == 0) {
                setRemovedCategories([category?.category_id])
            }
            else if (removedCategories?.length != 0) {
                setRemovedCategories([...removedCategories, category?.category_id]);
            }
        }

        if (addedCategories?.length == 0 && !defaultCategory?.some((selectedCategory) => selectedCategory?.category_id == category?.category_id)) {
            var addedCategoryIds = []
            addedCategoryIds?.push(category?.category_id)
            setAddedCategories(addedCategoryIds)
        }

        else if (addedCategories?.length > 0 && !addedCategories?.some((category_id) => category_id == category?.category_id)) {
            setAddedCategories([...addedCategories, category?.category_id]);
        }

        else {
            setAddedCategories(addedCategories?.filter((selectedCategory) => selectedCategory != category?.category_id));
        }
    }

    //Add the selected products to line items
    const onClickAddLineItems = () => {
        var newLineItems = [] //array of objects
        var getAddCategory_id = [] //array of objects
        //display the items based on the selected products

        selectedCategories?.map((selectedCategories) => {
            newLineItems?.push({ category_id: selectedCategories?.category_id, store_id: store?.id, name: selectedCategories?.name })
            getAddCategory_id.push(selectedCategories.category_id)
        })
        // console.log("newline items during add line items - ", newLineItems)
        setLineItems(newLineItems)
        setAddedCategories(getAddCategory_id)
        setMode('close')
        setSearchCategoryName("")
    }

    //remove the line items
    const onClickRemoveCategory = (productIndex, category_id) => {
        //remove the line item from the line items
        setLineItems(lineItems.filter((v, i) => i != productIndex))
        //remove the line item from the selected products list
        setSelectedCategories(selectedCategories?.filter((v, i) => i != productIndex))
        //remove the line item from the selected products list
        setAddedCategories(addedCategories.filter((v, i) => i != productIndex))
        setDefaultCategory(defaultCategory.filter((v, i) => i !== productIndex))
        setRemovedCategories((previousproducts) => [...previousproducts, category_id])
    }

    //close the dropdown when click to cancel button
    const onClickCloseDropdown = () => {
        setMode('close')
        setSearchCategoryName("")
        //clear the selected products when click to cancel button
        if (lineItems?.length == 0) {
            setSelectedCategories([])
        }
        else if (lineItems?.length != 0) {
            setSelectedCategories(lineItems)
            if (selectedCategories?.length != lineItems?.lenght) {
                setAddedCategories(lineItems?.map(category => category?.category_id) || []);
            }
        }
    }

    const onChageSlug = (event) => {
        const value = event.target.value;
        const slug = value
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace consecutive spaces with a single hyphen
            .replace(/-+/g, '-') // Replace consecutive hyphens with a single hyphen
            .replace(/[^a-zA-Z0-9-]/g, '') // Remove any characters other than alphanumeric and hyphens
            ?.trim(); // Remove leading and trailing spaces

        setProductSlug(slug);
    };

    const onOpen = (config) => {
        setConfig(config)
    }

    const onClose = (addedSelectedImages, removedSelectedImages) => {
        setConfig(false)
        if (config?.acceptMultipleFiles) {

            if (addedImages?.length == 0) {
                setAddedImages(addedSelectedImages)
            }

            if (addedImages?.length != 0) {
                setAddedImages((previousImages) => [
                    ...previousImages,
                    ...addedSelectedImages
                        .map(item => Number(item))
                        .filter(item => typeof item === 'number')
                ]);

            }

            if (removedImages?.length == 0 && removedSelectedImages) {
                setRemovedImages(removedSelectedImages)
            }
            else if (removedImages?.length != 0 && removedImages.length != undefined && removedSelectedImages) {
                setRemovedImages((removedPreviousImages) => [...removedPreviousImages, removedSelectedImages])
            }
            else if (removedSelectedImages) {
                setRemovedImages(removedSelectedImages)
            }
        }
    }

    const onSelectImages = (images) => {
        // console.log("images",images)
        if (config.acceptMultipleFiles == false) {
            if (config.printImage) {
                setContentForPrint({ ...content_for_printing, [config.printImage]: images })
            }
            else if (config.featuredImage) {
                setFeaturedImage(images)
            } else {
                setMetaImage(images)
            }
        }
        else if (config.acceptMultipleFiles == true) {
            setSelectedImage(images)
        }

    }

    const onRemovePreviewImageIds = (imageIds) => {
        if (removedImages?.length == 0 && removedImages?.length != undefined) {
            setRemovedImages([imageIds])
        }
        else if (removedImages?.length != 0 && removedImages?.length != undefined && imageIds?.length != 0) {
            setRemovedImages((PreviousImages) => [...PreviousImages, imageIds])
        }
    }

    const onRemoveFeaturedImagIds = () => {
        setFeaturedImage(null)
    }

    const onRemoveMetaImageIds = () => {
        setMetaImage(null)
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const status = Object.keys(product_page_labels?.product_add_and_edit_page_status)?.map((name) => {
        const value = product_page_labels?.product_add_and_edit_page_status[name];
        return `${name},${value}`;
    });

    const onOpenMessagePopUp = () => {
        setOpenDelectPopup(true)
    }

    const onProductDelete = () => {
        callAPI(`/admin/products/${id}/delete`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
            }, navigate, setLoading)
            .then(data => {
                // console.log("admin users update api response " + JSON.stringify(data));
                if (data.success) {
                    navigate("/admin/products")
                    localStorage.setItem('Message', product_page_message?.deleted_message)
                } else {
                    const errorObj = data?.errors?.find(error => error?.field_name == "product_id");
                    // console.log("errors", data.errors)
                    if (errorObj) {
                        const errorMessage = errorObj?.message;
                        setAlert({ text: errorMessages?.[errorMessage], type: alert_message_type.error })
                        setOpenDelectPopup(false)
                    }
                }
            }
            )
    }

    const onCloseMessagePopUp = () => {
        setOpenDelectPopup(false)
    }
    const handleDragEndImages = (images) => {
        if (images) {
            const imageIds = images.map(item => parseInt(item.image_id));
            setAddedImages(imageIds);
        }
    }

    const productType = Object.keys(product_page_labels?.product_type)?.map((name) => {
        const value = product_page_labels?.product_type[name];
        return `${name},${value}`;
    });

    const onChangeTextEditorValue = (productDescriptions) => {
        setDescription(productDescriptions)
    }

    const onCloseDropdown = () => {
        setMode("close")
        setSearchCategoryName("")
    }
    

    return (
        <React.Fragment>
            <button className="btn btn-primary saveButton" onClick={saveProduct}>{product_page_labels?.product_edit_page_labels?.save_btn_label}</button>
            <button className="btn delectButton" onClick={() => onOpenMessagePopUp()}>{product_page_labels?.product_edit_page_labels?.delete_btn_label}</button>
            {openDelectPopup && <PopUpMessage delectMessage={true} isDelect={true} onDelete={onProductDelete} onClose={onCloseMessagePopUp} />}

            {alert?.text == errorMessages?.product_linked_with_order ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            <div className="row">
                <div className="col-lg-8">
                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>{product_page_labels?.product_edit_page_labels?.title}</h2>
                        </div>
                        <div className="card-body">
                            <div className="ec-store-uploads">
                                <label className="form-label mandatory_field">{product_page_labels?.product_add_and_edit_page_labels?.name}</label>
                                <textarea type="text" className="form-control" maxLength="105" rows={3} name='name' onChange={onChangeField} value={formValues['name'] || ""} />
                                <div className="errorMessage">
                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'name', errors })] || ErrorMessage({ fieldName: 'name', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                </div>
                                <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.short_description}</label>
                                <p style={{ fontSize: "12px" }}>Characters remaining: {1000 - (short_description.length>0?short_description.length:0)}</p>
                                <div className="short_describtion">
                                    {/* <ReactQuill className="h-100" value={formValues?.short_description || ""} onChange={(value) => quillChange("short_description", value)} /> */}
                                    <TextEditor onChangeTextEditorValue={setShortDescription} productDescription={formValues['short_description'] || ""} />
                                </div>
                                <div className="errorMessage">
                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'short_description', errors })] || ErrorMessage({ fieldName: 'short_description', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                </div>
                                <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.description}</label>
                                <div className="describtion" >
                                    <TextEditor onChangeTextEditorValue={onChangeTextEditorValue} productDescription={formValues['description'] || ""} />
                                </div>
                                <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.slug}</label>
                                <input type="text" className="form-control" name='slug' value={productSluge || ""} maxLength="100" onChange={onChageSlug} />
                                <div className="errorMessage">
                                    <Message text={errorMessages[ErrorMessage({ fieldName: 'slug', errors })] || ErrorMessage({ fieldName: 'slug', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                </div>
                                <div className="d-flex justify-content-between  ">
                                    <div className="col-lg-3 ">
                                        <label className="form-label mandatory_field">{product_page_labels?.product_add_and_edit_page_labels?.price}</label>
                                        <input type="number" className="form-control " name='price' placeholder='0.00' onChange={onChangeField} value={formValues['price'] || ""} onWheel={numberInputOnWheelPreventChange} onKeyPress={(e) => {
                                            const disallowedCharacters = ["-", "+"];
                                            const inputValue = e.target.value;

                                            if (disallowedCharacters.includes(e.key)) {
                                                e.preventDefault();
                                            }
                                            if (inputValue.includes(".") && inputValue.split(".")[1].length >= 2) {
                                                e.preventDefault();
                                            }
                                        }}
                                            onKeyDown={(e) => {
                                                // Prevent up and down arrow key actions
                                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                    e.preventDefault();
                                                }
                                            }}

                                        />
                                        <div className="errorMessage">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'price', errors })] || ErrorMessage({ fieldName: 'price', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label ">{product_page_labels?.product_add_and_edit_page_labels?.stock}</label>
                                        <input type="number" className="form-control " name='stock' placeholder='Stock' onChange={onChangeField} value={formValues['stock'] || ""} onWheel={numberInputOnWheelPreventChange} onKeyPress={(e) => {
                                            const disallowedCharacters = ["-", "+"];
                                            const inputValue = e.target.value;

                                            if (disallowedCharacters.includes(e.key)) {
                                                e.preventDefault();
                                            }
                                            if (inputValue.includes(".") && inputValue.split(".")[1].length >= 2) {
                                                e.preventDefault();
                                            }
                                        }}
                                            onKeyDown={(e) => {
                                                // Prevent up and down arrow key actions
                                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                                    e.preventDefault();
                                                }
                                            }}

                                        />
                                        <div className="errorMessage">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'stock', errors })] || ErrorMessage({ fieldName: 'stock', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label mandatory_field">{product_page_labels?.product_add_and_edit_page_labels?.sku}</label>
                                        <input type="text" className="form-control " name='sku' placeholder='SKU' maxLength="30" onChange={onChangeField} value={formValues['sku'] || ""} />
                                        <div className="errorMessage">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'sku', errors })] || ErrorMessage({ fieldName: 'sku', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="mb-3 mt-1 col-lg-5">
                                        <label className="form-label mandatory_field">{product_page_labels?.product_add_and_edit_page_labels?.product_type}</label>
                                        <select className="form-control" name='product_type' onChange={onChangeField} value={formValues['product_type'] || ""}>
                                            <option hidden>Select</option>
                                            {productType &&
                                                productType?.map((optionString) => {
                                                    const [name, value] = optionString.split(',');
                                                    return <option value={name} key={name}>{value}</option>;
                                                })
                                            }
                                        </select>
                                        <div className="errorMessage mt-3">
                                            <Message text={errorMessages[ErrorMessage({ fieldName: 'product_type', errors })] || ErrorMessage({ fieldName: 'product_type', errors })} type={"errorMsg"} showMessage={showErrorMessage} />
                                        </div>
                                    </div>
                                    <div className="mt-1 col-lg-6">
                                        <label className="form-label mandatory_field">Orientation</label>
                                        <div className="d-flex justify-items-center">
                                            <div className="d-flex">
                                                <input type="radio" name="card_orientation" checked={formValues['card_orientation'] === Orientations["portrait"]} value={Orientations["portrait"]} onClick={onChangeField} />
                                                <p className=" px-3" style={{ paddingTop: 7 }}>Portrait</p>
                                            </div>
                                            <div className="d-flex ml-5">
                                                <input type="radio" name="card_orientation" checked={formValues['card_orientation'] === Orientations["landscape"]} value={Orientations["landscape"]} onClick={onChangeField} />
                                                <p className=" px-3" style={{ paddingTop: 7 }}>Landscape</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 d-flex col-lg-12 justify-content-between ">
                        <div style={{ width: "45%" }}>
                            <div className="card card-default">
                                <div className="card-header card-header-border-bottom">
                                    <h2>{product_page_labels?.product_add_and_edit_page_labels?.featured_image}</h2>
                                </div>
                                <div className="card-body p-3">
                                    <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={savedFeaturedImage} userProfile={false} onOpen={onOpen} selectedImageInfo={featuredImage} onRemoveImageIds={onRemoveFeaturedImagIds} deleteDisable={true} />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "53%" }}>
                            <ImagesPreview acceptMultipleFiles={true} savedImages={formValues?.images} onOpen={onOpen} selectedImageInfo={selectedImage} onRemoveImageIds={onRemovePreviewImageIds} deleteDisable={true} handleDragEndImages={handleDragEndImages} />
                        </div>
                    </div>
                    {formValues['product_type'] == "PERSONALIZED-CRD" &&
                        <div className="card card-default mt-5">
                            <div className="card-header card-header-border-bottom">
                                <h2>{product_page_labels?.product_add_and_edit_page_labels?.content_for_printing}</h2>
                            </div>
                            <div className="card-body">
                                <div className="d-flex flex-wrap">
                                    <div className="w-50 p-2 mb-2" >
                                        <label>Page 1 (Image)</label>
                                        <div style={{ height: 270 }}>
                                            <ImagesPreview acceptMultipleFiles={false} orientation={formValues['card_orientation']} printImage={"page_1"} userProfile={false} onOpen={onOpen} selectedImageInfo={content_for_printing?.page_1} onRemoveImageIds={() => { setContentForPrint({ ...content_for_printing, front: null }) }} deleteDisable={true} />
                                        </div>
                                    </div>
                                    <div className="w-50 p-2 page_2_portrait">
                                        <label>Page 2 (Copy)</label>
                                        <TextEditor  onChangeTextEditorValue={SetPage2Content} productDescription={page_content?.page_2 || ""} />
                                        {/* <ReactQuill theme="snow" className="h-200" value={page_content?.page_2 || ""} onChange={(value) => setPageContent({ ...page_content, page_2: value })} /> */}
                                    </div>
                                    <div className="w-50 p-1">
                                        <label>Page 3 (Copy) </label>
                                        <textarea type="text" name='page_3' className="form-control" rows={formValues['card_orientation'] === Orientations["portrait"] ? 11 : 6} value={page_content?.page_3 || ""} style={{ padding: 15, borderRadius: 10 }} onChange={(e) => setPageContent({ ...page_content, [e.target.name]: e.target.value })} />
                                    </div>
                                    <div className="w-50 p-2">
                                        <label>Page 4 (Image)</label>
                                        <ImagesPreview acceptMultipleFiles={false} orientation={formValues['card_orientation']} printImage={"page_4"} userProfile={false} onOpen={onOpen} selectedImageInfo={content_for_printing?.page_4} onRemoveImageIds={() => { setContentForPrint({ ...content_for_printing, back: null }) }} deleteDisable={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="card card-default mt-5">
                        <div className="card-header card-header-border-bottom">
                            <h2>{product_page_labels?.product_add_and_edit_page_labels?.categories}</h2>
                        </div>
                        <div className="card-body">
                            <div className="productSearchWrapper">
                                <div className="search-form d-lg-inline-block searchWrapper">
                                    <div className="input-group">
                                        <input type="text" name="query" id="search-input" className="form-control searchInput" placeholder={`${product_page_labels?.product_add_and_edit_page_labels?.category_placeholder}`} onChange={onSearchCategoryName} onClick={onClickListCatrgory} value={searchCategoryName || ""} />
                                        <button type="button" name="search" id="search-btn" className="btn btn-flat" onClick={onClickCategorySearchIcon}>
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                    </div>
                                </div>
                                {mode == 'open' &&
                                    <div className={`${categories ? "categorieList" : "noCategorieList"}`}>
                                        <ul id="search-results">
                                            {categories?.map((list, index) => {
                                                var alreadySelectedItem = lineItems?.find((item) => item?.category_id == list?.category_id)
                                                var defaultSelectedProduct = defaultCategory?.find((item) => item?.category_id == list?.category_id)
                                                const isCategoryInSelected = selectedCategories?.some((selectedCategory) => selectedCategory?.category_id == list?.category_id);
                                                // console.log("isCategoryInSelected",isCategoryInSelected)
                                                // console.log("defaultSelectedProduct",defaultSelectedProduct)
                                                return (
                                                    <li
                                                        key={index}
                                                        className={`${defaultSelectedProduct || isCategoryInSelected ? "hasSelected seletedLineItem" : ""} productDropDown`}
                                                    >
                                                        <div
                                                            className={`${defaultSelectedProduct || isCategoryInSelected ? "hasSelected seletedLineItem" : ""} w-100`}

                                                            onClick={() => onChangeFields(!alreadySelectedItem, list)}>
                                                            <input
                                                                type="checkbox"
                                                                id={list?.category_id}
                                                                name={list?.category_id}
                                                                value={list?.category_id}
                                                                checked={defaultSelectedProduct || isCategoryInSelected}
                                                                onChange={() => { }}
                                                            />
                                                            <span className="checkBoxLabel">
                                                                {list?.name}
                                                            </span>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                            {categories == '' && <p className="text-center mt-3 mb-5">{product_page_message?.category_not_found}</p>}
                                        </ul>
                                        {categories != '' && categories?.length != 0 &&
                                            <div className="d-flex categorieListbutton mt-3">
                                                <button className="addButton" onClick={onClickAddLineItems}>{product_page_labels?.product_add_and_edit_page_labels?.category_btn_add}</button>
                                                <button className="cancelButton" onClick={onClickCloseDropdown}>{product_page_labels?.product_add_and_edit_page_labels?.category_btn_cancel}</button>
                                                <button className="ml-auto addButton" onClick={() => onCloseDropdown()}>{product_page_labels?.product_add_and_edit_page_labels?.category_btn_close}</button>
                                            </div>
                                        }
                                    </div>
                                }

                                <>
                                    {lineItems?.map((lineItem, index) => {
                                        return (
                                            <div key={index} className="searchList">
                                                <span>{lineItem?.name}</span>
                                                <span className="removeProduct" onClick={() => onClickRemoveCategory(index, lineItem?.category_id)}><span className="mdi mdi-close"></span></span>
                                            </div>
                                        )
                                    })}

                                </>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card card-default">
                        <div className="card-body">
                            <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.status}</label>
                            <select className="form-control" name="status" onChange={onChangeField} value={formValues['status'] || ''} >
                                {status &&
                                    status?.map((optionString, index) => {
                                        const [name, value] = optionString.split(',');
                                        return <option value={name} key={index}>{value}</option>;
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="card card-default mt-5">
                        <div className="card-header card-header-border-bottom">
                            <h2>{product_page_labels?.product_add_and_edit_page_labels?.seo}</h2>
                        </div>
                        <div className="card-body">
                            <div className="ec-store-uploads">
                                <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.meta_title}</label>
                                <input type="text" className="form-control" maxLength="100" name='meta_title' onChange={onChangeField} value={formValues['meta_title'] || ""} />
                                <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.meta_description}</label>
                                <textarea type="text" className="form-control" rows="4" cols="50" name='meta_description' maxLength="1000" onChange={onChangeField} value={formValues['meta_description'] || ""} />
                                <label className="form-label">{product_page_labels?.product_add_and_edit_page_labels?.meta_image}</label>
                                <ImagesPreview acceptMultipleFiles={false} featuredImage={false} savedImages={savedMetaImages} userProfile={false}
                                    onOpen={onOpen} selectedImageInfo={metaImage} onRemoveImageIds={onRemoveMetaImageIds} deleteDisable={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <ImageSelector Config={config} onClose={onClose} onSelectImageInfo={onSelectImages} />
            </div>

            <div className="text-right">
                <button className="btn btn-primary" onClick={() => saveProduct()}>{product_page_labels?.product_edit_page_labels?.save_btn_label}</button>
            </div>
        </React.Fragment >
    )
} 