import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { callAPI, useUser } from "../../../Utils/utils";
import { recipients_address, customer_page_labels, category_page_labels } from "../../../Constants/Labels";
import { PopUpMessage } from "../../Global/PopupMessage/PopupMessage";
import { customer_page_message } from "../../../Constants/Messages";
import Loader from "../../Global/Loader/Loader";
import { countries } from "../../../Constants/Countries/countries";
import Pagination from "../../Global/Pagination/Pagination";
import Alert from "../../Alert/Alert";

export default function AddressTable({ onShowUpload }) {
    var { id } = useParams();
    const navigate = useNavigate();
    const [selectedRecipientAddressIds, setSelectedRecipientAddressIds] = useState([]);
    const { user, setUser } = useUser();
    const [alert, setAlert] = useState()
    const [loading, setLoading] = useState(false)
    const [payLoad, setPayLoad] = useState()
    const [address, setAddress] = useState([])
    const [openDelectPopup, setOpenDelectPopup] = useState();
    const [searchValue, setSearchValue] = useState()
    const [resultInfo, setResultInfo] = useState({
        total_rows: "",
        fetched_rows: "",
        total_pages: "",
        page_size: 0,
        current_page: 1,
        success: true,
        errors: ""
    })

    // page onloade get the data from database based on customerId
    useEffect(() => {
        if (id) {
            var inital_pay_load = { 'customer_id': id }
            var pay_load = payLoad || inital_pay_load
            // console.log("pay_load", pay_load)
            callAPI("/admin/address/list-by-customer",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success == true) {
                        setAddress(data?.records);
                        setResultInfo({
                            total_rows: data.total_rows,
                            fetched_rows: data.fetched_rows,
                            total_pages: data.total_pages,
                            page_size: data.page_size,
                            current_page: data?.current_page,
                            success: data.success,
                            errors: data.errors
                        })
                    } else {
                        // console.log('Error occurred. Please try again.')
                    }
                }
                )
        }
    }, [payLoad, alert])

    const exportRecipients = () => {
        var pay_load = { 'customer_id': id }
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL

        fetch(baseUrl + '/admin/address/export-recipients',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                console.log("data", data.success)
                    if (data.constructor == Object) {
                    } else {
                        data.blob().then(blob => {
                            const fileSize = blob.size
                            console.log("error", fileSize);
                            if (fileSize > 500) {
                                // Creating new object of PDF file
                                const fileURL = window.URL.createObjectURL(blob);
                                // Setting various property values
                                let alink = document.createElement('a');
                                alink.href = fileURL;
                                alink.download = "recipients_export.xlsx"
                                alink.click();
                            } else {
                               
                            }
                        })
                    }
            }
            )
    }

    const onPageSelect = (currentPage) => {
        onClickSearchBtn(currentPage)
    }

    //during on search icon clicked get the result of store
    const onClickSearchBtn = (currentPage) => {
        getSearchResult(currentPage)
    }

    const onChangeSearchValue = (event) => {
        // console.log("on search value - ", event.target.value ? true : false)
        setSearchValue(event.target.value)

        var pay_load = {
            'customer_id': id,
            "page_size": 10,
            "current_page": 1
        }
        if (event.target.value) {
            pay_load.filters = {
                "conditions": [
                    { "field": "ca.name", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "line_1", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "line_2", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "city", "operator": "like", "value": event.target.value, "dataType": "string" },
                    { "field": "state", "operator": "like", "value": event.target.value, "dataType": "string" }
                ],
                "logic": "1-or-2-or-3-or-4-or-5"
            }
        }

        setPayLoad(pay_load)
    }

    const getSearchResult = (currentPage) => {

        var pay_load = {
            'customer_id': id,
            "page_size": 10,
            "current_page": currentPage
        }
        if (searchValue) {
            pay_load.filters = {
                "conditions": [
                    { "field": "ca.first_name", "operator": "like", "value": searchValue, "dataType": "string" },
                    { "field": "ca.last_name", "operator": "like", "value": searchValue, "dataType": "string" },
                    { "field": "line_1", "operator": "like", "value": searchValue, "dataType": "string" },
                    { "field": "line_2", "operator": "like", "value": searchValue, "dataType": "string" },
                    { "field": "city", "operator": "like", "value": searchValue, "dataType": "string" },
                    { "field": "state", "operator": "like", "value": searchValue, "dataType": "string" }
                ],
                "logic": "1-or-2-or-3-or-4-or-5-or-6"
            }
        }
        setPayLoad(pay_load)
    }

    // onclick to navigate addressedit page based on id
    const editAddress = (address_id) => {
        navigate(`/admin/customer/edit/${id}/address/edit/${address_id}`)
    }

    //on enter search the store list
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getSearchResult()
        }
    }

    const onSelectRecipient = (event, address_id) => {

        if (event.target.checked) {
            if (selectedRecipientAddressIds?.length == 0) {
                setSelectedRecipientAddressIds([address_id])
            }
            else if (selectedRecipientAddressIds?.length != 0) {
                setSelectedRecipientAddressIds((previousRecipientIds) => [...previousRecipientIds, address_id])
            }
        }
        else {
            // If the checkbox is unchecked, remove the address_id from the selectedRecipientAddressIds array
            setSelectedRecipientAddressIds((previousRecipientIds) =>
                previousRecipientIds.filter((Recipient_address_id) => Recipient_address_id !== address_id)
            );
        }
    }

    const onOpenMessagePopUp = () => {
        setOpenDelectPopup(true)
    }

    const onDelete = () => {
        var pay_load = {
            customer_id: parseInt(id),
            customer_address_ids: selectedRecipientAddressIds
        }
        setOpenDelectPopup(false)
        // console.log("pay_load",pay_load)

        callAPI(`/admin/address/delete-recipients`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading)
            .then(data => {
                // console.log("admin users update api response " + JSON.stringify(data));
                if (data.success) {
                    var delete_message = selectedRecipientAddressIds?.length == 1 ? "Recipient Deleted successfully" : "Recipients Deleted successfully"
                    setAlert({ text: delete_message, type: "success" })
                } else {
                    setAlert({ text: "Error occured in recipient delete.", type: "error" })
                }
            }
            )
    }

    const onCloseMessagePopUp = () => {
        setOpenDelectPopup(false)
    }


    return (
        <React.Fragment>
            <>
                {alert?.text == "Recipient Deleted successfully" || alert?.text == "Recipients Deleted successfully" || alert?.text == "Error occured in recipient delete." ?
                    <div className="text-center">
                        <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                    </div>
                    : ''}
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="ec-store-list card card-default">
                            <div className='card-body'>
                                <div className="d-flex justify-content-end">
                                    <button className="btn delectBtn  position-relative mb-1 mr-2" disabled={address?.length == 0 ? true : false} onClick={() => onOpenMessagePopUp()}>{category_page_labels?.category_edit_page_labels?.delete_btn_label}</button>
                                    <button className="btn delectBtn  position-relative mb-1 mr-2" onClick={() => exportRecipients()}>{customer_page_labels?.customer_edit_page_labels?.export_btn}</button>
                                    <button className="btn delectBtn  position-relative mb-1 mr-2" onClick={() => onShowUpload()}>{customer_page_labels?.customer_edit_page_labels?.upload_btn}</button>
                                    <button className="btn btn-primary position-relative mb-1" onClick={() => navigate(`/admin/customer/edit/${id}/address/new`)}>{customer_page_labels?.customer_edit_page_labels?.recipients_btn_label}</button>
                                    {openDelectPopup && <PopUpMessage delectMessage={true} isDelect={true} onDelete={onDelete} onClose={onCloseMessagePopUp} recipientsCount={selectedRecipientAddressIds?.length} back="Recipients" newMessage={"Are you sure you want to delete the selected recipients?"} />}
                                </div>
                                <>
                                    {address?.length == 0 && searchValue == undefined ?
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="ec-store-list card card-default">
                                                    <div className="card-body m-auto">
                                                        <p className="text-center">{customer_page_message?.recipients}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="search-form d-lg-inline-block searchWrapper ">
                                                <div className="input-group ">
                                                    <input type="text" className="form-control searchInput"
                                                        placeholder="Search by Recipient" value={searchValue} onChange={onChangeSearchValue} onKeyDown={handleKeyDown} />
                                                    <span className="search_icon">
                                                        <i className="mdi mdi-magnify"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {loading && <Loader className={"table_loader"} />
                                            }
                                            {!loading && <>
                                                {address?.length === 0 ?
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card card-default">
                                                                <div className="card-body m-auto defaultText">
                                                                    <p className="text-center">{customer_page_message?.recipients}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th className="col-lg-2">{recipients_address?.name}</th>
                                                                    <th className="col-lg-2">{recipients_address?.company}</th>
                                                                    <th className="col-lg-2">{recipients_address?.line_1}</th>
                                                                    <th className="col-lg-1">{recipients_address?.city}</th>
                                                                    <th className="col-lg-1">{recipients_address?.state}</th>
                                                                    <th className="col-lg-1">{recipients_address?.zip}</th>
                                                                    <th className="col-lg-1">{recipients_address?.country}</th>
                                                                    <th className="col-lg-3">{recipients_address?.personalisation_message}</th>
                                                                    <th className="col-lg-1">{recipients_address?.action}</th>
                                                                </tr>
                                                            </thead>


                                                            {
                                                                address?.map((address) => {
                                                                    const isValueAvailable = selectedRecipientAddressIds.includes(address?.customer_address_id);
                                                                    // console.log("address",address)
                                                                    return (
                                                                        <tbody key={address?.customer_address_id} className={isValueAvailable ? "hasSelected" : ""}>
                                                                            <tr onClick={() => onSelectRecipient({ target: { checked: !isValueAvailable } }, address.customer_address_id)} role="button" className={`${isValueAvailable ? "recipientHasSelected" : ""}`}>
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={address.customer_address_id}
                                                                                        name={address.customer_address_id}
                                                                                        value={address.customer_address_id}
                                                                                        checked={isValueAvailable}
                                                                                        className="mb-0"
                                                                                        role="button"
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    {/* {address.first_name} {address.last_name} */}
                                                                                    {address?.salutation == "NULL" ? "" : address?.salutation} {address?.name == "NULL" ? "" : address?.name}
                                                                                </td>
                                                                                <td>
                                                                                    {address?.line_3 == "NULL" ? "" : address?.line_3}
                                                                                </td>
                                                                                <td>
                                                                                    {address?.line_1 == "NULL" ? "" : address?.line_1} {address?.line_2 == "NULL" ? "" : address?.line_2}
                                                                                </td>
                                                                                <td>{address?.city == "NULL" ? "" : address?.city}</td>
                                                                                <td>{address?.state == "NULL" ? "" : address?.state}</td>
                                                                                <td>{address?.zip == "NULL" ? "" : address?.zip}</td>
                                                                                <td>{address?.countries == "NULL" ? "" : countries[address?.country]}</td>
                                                                                <td>{address?.personalisation == "NULL" ? "" : address?.personalisation}</td>
                                                                                <td>
                                                                                    <span className="mdi mdi-account-edit icon" onClick={() => editAddress(address?.address_id)} role="button"></span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    );
                                                                })

                                                            }
                                                        </table>
                                                        <div className="paginationWrapper">
                                                            <Pagination totalRows={resultInfo?.total_rows} fetchedRows={resultInfo?.fetched_rows}
                                                                totalPages={resultInfo?.total_pages} pageSize={resultInfo?.page_size}
                                                                currentPage={resultInfo?.current_page} onPageSelect={onPageSelect}
                                                                label={recipients_address?.recipients_label} loader={loading} />
                                                        </div>
                                                    </div>
                                                }
                                            </>}
                                        </>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </React.Fragment >
    )
}