export const errorMessages = {
  "name_personalization":"Name is required",
  "name":"Name is required",
  "email": "Email is required",
  "message":"Message is required",
  "product_type_reqd":"Product Type required",
  "first_name_reqd": "First Name is required",
  "last_name_reqd": "Last Name is required",
  "category_exists": "Category already exists",
  "email_reqd": "Email is required",
  "email_invalid": "Invalid Email",
  "email_exists": "Email already exists",
  "emailnotavailable": "Email address doesn't exist",
  "product_not_found": "Product not found",
  "category_not_found": "Category not found",
  "customer_not_found": "Customer not found",
  "order_not_found": "Order not found",
  "user_not_found": "User not found",
  "password": "Password is required",
  "password_not_created": "Password has not been created",
  "invalid_password": "Invalid Password",
  "confirm_password_reqd": "Confirm Password is required",
  "password_confirm_mismatch": "Confirm Password and Password do not match",
  "store_name_reqd": "Store Name is required",
  "store_exists": "Store with the name already exists",
  "name_reqd": "Name is required",
  "product_name_exists": "Product with the name already exists",
  "name_error": "Name already exists",
  "name_already_exists": "Name already exists",
  "sku_reqd": "SKU is required",
  "product_SKU_exists": "SKU already exists",
  "stock_reqd": "Stock is required",
  "slug_reqd": "Slug is required",
  "sub_domain_reqd": "GiftingGood url is required",
  "sub_domain_exists": "GiftingGood url already exists",
  "product_slug_exists": "Slug already exists",
  "slug_exists": "Slug already exists",
  "price_reqd": "Price is required",
  "line_1_reqd": "Address is required",
  "city_reqd": "City is required",
  "state_reqd": "State is required",
  "zip_reqd": "Zip is required",
  "country_reqd": "Country is required",
  "store_name_reqd": "Name is required",
  "type_reqd":"Type is required",
  "rate_reqd": "Rate is required",
  "valid_from_reqd": "Valid from is required",
  "window_type_reqd": "Window Type is required",
  "from_date_reqd": "From date is required",
  "to_date_reqd": "To date is required",
  "status": "Status is required",
  "payment_mode": "Payment mode is required",
  "payment_status": "Payment status is required",
  "billing_address": "Billing address is required",
  "return_address": "Return address is required",
  "store_inactv": "The store is inactive. Please contact your admin.",
  'user_inactv': "Your account is inactive. Please contact your admin.",
  'unexpected_error': "Unexpected error happened. Please try again later.",
  'temp_file_remove_error': "Error occurred while removing temp file",
  'password_min_8_chars': "Password should be minimum 8 characters in length",
  'password_min_1_lowercase': "Password should have atleast 1 lowercase character",
  'password_min_1_uppercase': "Password should have atleast 1 uppercase character",
  'password_min_1_special': "Password should have atleast 1 special character",
  'address_not_found': "Address is not found",
  'address_id_reqd': "Address is required",
  'time_window_reqd': "Time Window is required",
  'image_name_exists': "Image with the name already exists",
  'cannot_delete_image_linked_to_product': "Image cannot be deleted as it is linked with other products",
  'payment_mode_reqd': "Payment mode is required",
  'billing_address_reqd': "Billing address is required",
  'payment_already_made': "Payment has already been made",
  'no_items_in_order': "Order does not have any items",
  'order_id_reqd': "Order is required",
  'product_name_exists': "Product with the name already exists",
  'product_sku_exists': "Product with the SKU already exists",
  'product_slug_exists': "Product with the slug already exists",
  'category_not_exists': "Category is invalid",
  'product_not_exists': "Product is invalid",
  'product_not_found': "Product not found",
  'store_exists': "Store with the name already exists",
  'store_email_exists': "Store with the email already exists",
  'password_invalid': "Invalid Password",
  'invalid_session_try_reset_again': "Invalid session. Please try resetting password again",
  'access_denied': "Access Denied",
  'store_id_reqd': "Store is required",
  'status_reqd': "Status is required",
  'product_id_reqd': "Product is required",
  'category_id_reqd': "Category is required",
  'old_password_mismatch': "Old Password and New Password do not match",
  'fetch_payment_details_error': "Error occurred while fetching payment details",
  'return_address_reqd': "Return / Shipping Address is required",
  'payment_mode_reqd': "Payment Mode is required",
  'store_not_found': "Store not found",
  'filters_reqd': "Filters is required",
  'secret_code_reqd': "Secret Code is required",
  'host_reqd': "Host is required",
  'old_password_reqd': "Old Password is required",
  'password_reqd': "Password is required",
  "all":"All fields are required",
  "Invalid_filter_value":"Invalid filter value",
  "image_type": "Please upload a JPEG or PNG image",
  "image_size": "Please upload an image below 500kb",
  "no_password":"Password has not been created",
  "recipient":"Please Select atleast one recipient",
  "customer": "Customer is required",
  "product" : "Product is required",
  "product_linked_with_order":"Product tied to order, can't delete.",
  "category_linked_with_product":"Category tied to product, can't delete.",
  "check_num_req":"Check number is required",
  "transaction_date_req":"Transaction date is required",
  "reference_num_req":"Reference number is required",
  "zipcode54":"Zip code for USA has to be 5 digits or 5-4 digits",
  "sender_name_on_card_reqd": "Sender's name on card is required",
  "personalisation_reqd":" Personal message is required",
  "addressee_name_reqd":"Addressee's name is required"
}
