import React, { useState, useEffect } from "react";
import { callAPI, useStore, usePage, useUser } from "../../../Utils/utils";
import { scrollToTop } from "../../Global/Scroll";
import { useNavigate } from "react-router-dom";
import { page_labels } from "../../../Constants/Labels";
import { select_store_message, home_page_message } from "../../../Constants/Messages"
import Alert from "../../Alert/Alert";
import Layout from "../../Global/Layout";
import Button from "../../Global/Button";
import BreadCrumb from "../../Global/BreadCrumb/BreadCrumb";
import ImagesPreview from "../../ImagesPreview/ImagesPreview";
import ImageSelector from "../../ImageSelector/ImageSelector";
import TextEditor from "../../Global/TextEditor/TextEditor"


const HomePage = () => {

    const { user, setUser } = useUser();
    const { store, setStore } = useStore();
    const [config, setConfig] = useState();
    const { page, setPage } = usePage();
    const [alert, setAlert] = useState()
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState();
    const [storeName, setStoreName] = useState([]);
    const [bannerBlock, setBannerBlock] = useState({});
    const [bannerDescription,setBannerDescription] = useState()
    const [animatedAndNonAnimatedTitle, setAnimatedAndNonAnimatedTitle] = useState({});
    const [IndividualAndBoxSetTitle, setIndividualAndBoxSetTitle] = useState({})
    const [catalogtBlock, setCatalogtBlock] = useState({});
    const [individualTextAndImageBlock, setIndividualTextAndImageBlock] = useState({})
    const [individualTextAndImageBlockImage, setIndividualTextAndImageBlockImage] = useState([])
    const [animatedtextAndImageBlock, setAnimatedTextAndImageBlock] = useState({})
    const [personalizedtextAndImageBlock, setPersonalizedTextAndImageBlock] = useState({})
    const [animatedtextAndImageBlockImage, setAnimatedTextAndImageBlockImage] = useState([])
    const [personalizedtextAndImageBlockImage, setPersonalizedTextAndImageBlockImage] = useState([])
    const [ImageAndTextBlock, setImageAndTextBlock] = useState({})
    const [ImageAndTextBlockImage, setImageAndTextBlockImage] = useState([])
    const [nonAnimatedCardBlock, setNonAnimatedCardBlock] = useState({})
    const [nonAnimatedCardBlockImage, setNonAnimatedCardBlockImage] = useState([])
    const [aboutBlockLeftContent, setAboutBlockLeftContent] = useState({})
    const [aboutBlockRightContent, setAboutBlockRightContent] = useState();
    const [bannerBlockImage, setBannerBlockImage] = useState([]);
    const [aboutBlockLogoImage, setAboutBlockLogoImage] = useState([]);
    const [aboutBlockBackgroundImage, setAboutBackgroundImage] = useState([]);
    const navigate = useNavigate();

    console.log("personalizedtextAndImageBlockImage",personalizedtextAndImageBlock,personalizedtextAndImageBlockImage);
    

    // store onchage value in state
    const onChangeBannerBlockField = (event) => {
        setBannerBlock({ ...bannerBlock, [event.target.name]: event.target.value })
    }

    const onChangeBannerBlockDescription = (event) => {
        if(bannerBlock){
        bannerBlock.description=event
        }
    }

    // store onchage value in state
    const onChangeCatalogtBlockField = (event) => {
        // Check if event.target and event.target.name are defined
        if (event.target && event.target.name) {
            const updatedCatalogBlock = {
                ...catalogtBlock,
                [event.target.name]: event.target.value,
            };
            setCatalogtBlock(updatedCatalogBlock);

            // Check if event.target.options and event.target.selectedIndex are defined
            if (event.target.options && event.target.selectedIndex !== undefined && updatedCatalogBlock.collection_name == undefined) {
                const selectedOption = event.target.options[event.target.selectedIndex];
                const collectionName = selectedOption.getAttribute("data-name");
                updatedCatalogBlock.collection_name = collectionName;
            }
        }
    }

    // store onchage value in state
    const onChangeAboutBlockLeftField = (event) => {
        setAboutBlockLeftContent({ ...aboutBlockLeftContent, [event.target.name]: event.target.value })
    }

    const onChangeAboutBlockRightField = (event) => {
        setAboutBlockRightContent({ ...aboutBlockRightContent, [event.target.name]: event.target.value })
    }

    const onChangeIndividualTextAndImageBlockField = (event) => {
        setIndividualTextAndImageBlock({ ...individualTextAndImageBlock, [event.target.name]: event.target.value })
    }

    const onChangeAnimatedTextAndImageBlockField = (event) => {
        setAnimatedTextAndImageBlock({ ...animatedtextAndImageBlock, [event.target.name]: event.target.value })
    }
    const onChangePersonalizedTextAndImageBlockField = (event) => {
        setPersonalizedTextAndImageBlock({ ...personalizedtextAndImageBlock, [event.target.name]: event.target.value })
    }

    const onChangeImageAndTextBlockField = (event) => {
        setImageAndTextBlock({ ...ImageAndTextBlock, [event.target.name]: event.target.value })
    }

    const onChangeNonAnimatedCardBlockField = (event) => {
        setNonAnimatedCardBlock({ ...nonAnimatedCardBlock, [event.target.name]: event.target.value })
    }

    const onChangeAnimatedAndNonAnimatedTitleField = (event) => {
        setAnimatedAndNonAnimatedTitle({ ...animatedAndNonAnimatedTitle, [event.target.name]: event.target.value })
    }

    const onChangeIndividualAndBoxSetTitleField = (event) => {
        setIndividualAndBoxSetTitle({ ...IndividualAndBoxSetTitle, [event.target.name]: event.target.value })
    }

    useEffect(() => {

        if (store?.id) {
            var pay_load = {
                "store_id": store?.id,
                "page_id": 1
            }

            callAPI("/admin/settings/pages/get",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading, setStore)
                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data.success) {

                        const bannerContent = data?.record?.config?.blocks[0]?.content;


                        const AnimatedAndNonAnimatedTitleBlock = data?.record?.config?.blocks[3]
                        const animatedtextAndImageContent = data?.record?.config?.blocks[5]?.content;
                        const nonAnimatedCardContent = data?.record?.config?.blocks[6]?.content;
                        const personalizedCardcontent= data?.record?.config?.blocks[4]?.content;
                        const IndividualAndBoxSetTitleBlock = data?.record?.config?.blocks[7]
                        const individualTextAndImageContent = data?.record?.config?.blocks[8]?.content;
                        const ImageAndTextContent = data?.record?.config?.blocks[9]?.content;
                        const aboutContent = data?.record?.config?.blocks[10]?.content;
                        let bannerLeftContent=bannerContent?.left_content
                        bannerLeftContent.img_button_url=bannerContent?.right_content?.img_button_url
                        setBannerBlock(bannerLeftContent);
                        setCatalogtBlock(data?.record?.config?.blocks[2]);
                        setAboutBlockLeftContent(aboutContent?.left_content);
                        setAboutBlockRightContent(aboutContent?.right_content);
                        // setPersonalizedTextAndImageBl
                        setPersonalizedTextAndImageBlock(personalizedCardcontent?.right_content)
                        setAnimatedAndNonAnimatedTitle(AnimatedAndNonAnimatedTitleBlock)
                        setAnimatedTextAndImageBlock(animatedtextAndImageContent?.left_content);
                        setNonAnimatedCardBlock(nonAnimatedCardContent?.right_content);

                        setIndividualAndBoxSetTitle(IndividualAndBoxSetTitleBlock)
                        setIndividualTextAndImageBlock(individualTextAndImageContent?.left_content);
                        setImageAndTextBlock(ImageAndTextContent?.right_content);

                        const bannerImage = bannerContent?.right_content;
                        const individualTextAndImageRightContent = individualTextAndImageContent?.right_content
                        const nonAnimatedCardContentLeftContent = nonAnimatedCardContent?.left_content
                        const animatedtextAndImageContentRightContent = animatedtextAndImageContent?.right_content
                        const ImageAndTextContentLeftContent = ImageAndTextContent?.left_content
                        const personalizedImage= personalizedCardcontent.left_content

                        if (bannerImage && Object.keys(bannerImage)?.length !== 0) {
                            setBannerBlockImage(bannerImage);
                        }

                        if (aboutContent?.right_content) {
                            const { logo, background_image } = aboutContent?.right_content;

                            if (logo && Object.keys(logo)?.length !== 0) {
                                setAboutBlockLogoImage(logo);
                            }

                            if (background_image && Object.keys(background_image)?.length !== 0) {
                                setAboutBackgroundImage(background_image);
                            }
                        }

                        if (individualTextAndImageRightContent && Object.keys(individualTextAndImageRightContent)?.length !== 0) {
                            setIndividualTextAndImageBlockImage(individualTextAndImageRightContent);

                        }

                        if (nonAnimatedCardContentLeftContent && Object.keys(nonAnimatedCardContentLeftContent)?.length !== 0) {
                            setNonAnimatedCardBlockImage(nonAnimatedCardContentLeftContent);
                        }

                        if (animatedtextAndImageContentRightContent && Object.keys(animatedtextAndImageContentRightContent)?.length !== 0) {
                            setAnimatedTextAndImageBlockImage(animatedtextAndImageContentRightContent);
                        }


                        if (ImageAndTextContentLeftContent && Object.keys(ImageAndTextContentLeftContent)?.length !== 0) {
                            setImageAndTextBlockImage(ImageAndTextContentLeftContent);
                        }
                        if(personalizedImage && Object.keys(personalizedImage)?.length !==0){
                            setPersonalizedTextAndImageBlockImage(personalizedImage)
                        }

                    } else {
                        // console.log("data.errors", data.errors)
                    }
                }
                )
        }
    }, [])

    // get data from database on page onload
    useEffect(() => {

        if (store?.id) {
            var pay_load = {
                "store_id": store?.id,
                "status": "active"
            }
            callAPI('/admin/category/by-store',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': user?.auth_token
                    },
                    body: JSON.stringify(pay_load)
                }, navigate, setLoading)

                .then(data => {
                    // console.log("api response " + JSON.stringify(data))
                    if (data?.success == true) {
                        setCategories(data?.records)
                    } else {
                        // console.log('Error occurred. Please try again.')
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (storeName?.length == 0 || storeName == undefined) {
            setStoreName(() => [store?.name])
        }
        else {
            setStoreName((existingStoreId) => [...existingStoreId, store?.name])
        }
    }, [store?.id])

    useEffect(() => {
        if (storeName[1] != undefined) {
            if (storeName[0] != storeName[1]) {
                navigate("/admin")
                setStoreName([])
            }
        }
    }, [storeName])

    // set the loading status into context whenever loading value changes  
    useEffect(() => {
        setPage({ isLoading: loading })
    }, [loading])


    const onOpenBannerImageBlockSelector = (config) => {
        config.bannerBlockImage = true
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = false
        config.ImageAndTextBlockImage = false
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onOpenaboutBlockLogoImageBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = true
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = false
        config.ImageAndTextBlockImage = false
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onOpenaboutBlockBackgroundImageImageBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = true
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = false
        config.NonAnimatedCardBlockImage = false
        config.ImageAndTextBlockImage = false
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onOpenIndividualTextAndImageBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = true
        config.AnimatedTextAndImageBlockImage = false
        config.NonAnimatedCardBlockImage = false
        config.ImageAndTextBlockImage = false
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onOpenAnimatedTextAndImageBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = true
        config.NonAnimatedCardBlockImage = false
        config.ImageAndTextBlockImage = false
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onOpenPersonalizedTextAndImageBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = false
        config.NonAnimatedCardBlockImage = false
        config.ImageAndTextBlockImage = false
        config.PersonalizedBlockImage = true
        setConfig(config)
    }

    const onOpenNonAnimatedCardBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = false
        config.ImageAndTextBlockImage = false
        config.NonAnimatedCardBlockImage = true
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onOpenImageAndTextBlockSelector = (config) => {
        config.bannerBlockImage = false
        config.aboutBlockLogoImage = false
        config.aboutBlockBackgroundImage = false
        config.IndividualTextAndImageBlockImage = false
        config.AnimatedTextAndImageBlockImage = false
        config.NonAnimatedCardBlockImage = false
        config.ImageAndTextBlockImage = true
        config.PersonalizedBlockImage = false
        setConfig(config)
    }

    const onClose = () => {
        setConfig(false)
    }

    const onSelectImages = (images) => {

        if (config?.bannerBlockImage) {
            setBannerBlockImage(images)
        }
        if (config?.aboutBlockLogoImage) {
            setAboutBlockLogoImage(images)
        }
        if (config?.aboutBlockBackgroundImage) {
            setAboutBackgroundImage(images)
        }
        if (config?.IndividualTextAndImageBlockImage) {
            setIndividualTextAndImageBlockImage(images)
        }
        if (config?.AnimatedTextAndImageBlockImage) {
            setAnimatedTextAndImageBlockImage(images)
        }
        if (config?.NonAnimatedCardBlockImage) {
            setNonAnimatedCardBlockImage(images)
        }
        if (config?.ImageAndTextBlockImage) {
            setImageAndTextBlockImage(images)
        }
        if(config?.PersonalizedBlockImage){
            setPersonalizedTextAndImageBlockImage(images)
        }
    }

    const onRemoveBannerBlockImage = () => {
        setBannerBlockImage(null)
    }

    const onRemoveAboutBlockLogo = () => {
        setAboutBlockLogoImage(null)
    }

    const onRemoveAboutBlockBackgroundImage = () => {
        setAboutBackgroundImage(null)
    }

    const onRemoveIndividualTextAndImageBlockImage = () => {
        setIndividualTextAndImageBlockImage(null)
    }

    const onRemoveAnimatedTextAndImageBlockImage = () => {
        setAnimatedTextAndImageBlockImage(null)
    }
    const onRemovePersonalizedTextAndImageBlockImage = () => {
        setPersonalizedTextAndImageBlockImage(null)
    }

    const onRemoveNonAnimatedCardBlockImage = () => {
        setNonAnimatedCardBlockImage(null)
    }

    const onRemoveImageAndTextBlockImage = () => {
        setImageAndTextBlockImage(null)
    }

    const onSave = () => {

        scrollToTop()

        var pay_load = {
            "page_id": 1,
            "store_id": parseInt(store?.id),
            "config": {
                "blocks": [
                    {
                        "label": "Banner",
                        "name": "banner",
                        "content": {
                            "left_content": {
                                "heading": bannerBlock?.['heading'],
                                "sub_heading": bannerBlock?.['sub_heading'] || "",
                                "description": bannerDescription || bannerBlock?.['description'] || "",
                                "button_label": bannerBlock?.['button_label'] || "",
                                "button_url": bannerBlock?.['button_url'] || "",
                            },
                            "right_content": {
                                "image_id": bannerBlockImage?.image_id,
                                "path": bannerBlockImage?.path,
                                "name": bannerBlockImage?.name,
                                "img_button_url":bannerBlock?.img_button_url
                            },
                        }
                    }, {
                        "label": "Process",
                        "name": "process",
                        "content": {
                            "title": "We Make it Easy for You",
                            "contentWrapper": [
                                {
                                    "img_url": "/img/card-design.png",
                                    "img_name": "card design",
                                    "description": "Select a Card Design"
                                },
                                {
                                    "img_url": "/img/write-message.png",
                                    "img_name": "write a message",
                                    "description": "Write a Personal  Message"
                                },
                                {
                                    "img_url": "/img/info.png",
                                    "img_name": "recipients info",
                                    "description": "Upload Your Recipients’ Info"
                                },
                                {
                                    "img_url": "/img/donation.png",
                                    "img_name": "donation amount",
                                    "description": "Choose Your Donation Amount"
                                },
                                {
                                    "img_url": "/img/print.png",
                                    "img_name": "print",
                                    "description": "We’ll Print and Mail Your Cards for You"
                                }
                            ]
                        }
                    }, {
                        "label": "Catalog",
                        "name": "catalog",
                        "heading": catalogtBlock?.['heading'] || "",
                        "collection_id": catalogtBlock?.['collection_id'] || "",
                        "collection_name": catalogtBlock?.['collection_name'] || "",
                        "limit": parseInt(catalogtBlock?.['limit']) || ""
                    },
                    {
                        "label": "E-CardSectionTitle",
                        "name": "e-cardSectionTitle",
                        "title": animatedAndNonAnimatedTitle?.['title']
                    },
                    {
                        "label": "Personalized Text And Image",
                        "name": "personalized_text_and_image",
                        "content": {
                            "right_content": {
                                "heading": personalizedtextAndImageBlock?.['heading'] || "",
                                "description": personalizedtextAndImageBlock?.['description'] || "",
                                "button_label": personalizedtextAndImageBlock?.['button_label'] || "",
                                "button_url": personalizedtextAndImageBlock?.['button_url'] || "",
                            },
                            "left_content": {
                                "image_id": personalizedtextAndImageBlockImage?.image_id,
                                "path": personalizedtextAndImageBlockImage?.path,
                                "name": personalizedtextAndImageBlockImage?.name
                            },
                        }
                    },
                    {
                        "label": "Animated Text And Image",
                        "name": "animated_text_and_image",
                        "content": {
                            "left_content": {

                                "heading": animatedtextAndImageBlock?.['heading'] || "",
                                "description": animatedtextAndImageBlock?.['description'] || "",
                                "button_label": animatedtextAndImageBlock?.['button_label'] || "",
                                "button_url": animatedtextAndImageBlock?.['button_url'] || "",
                            },
                            "right_content": {
                                "image_id": animatedtextAndImageBlockImage?.image_id,
                                "path": animatedtextAndImageBlockImage?.path,
                                "name": animatedtextAndImageBlockImage?.name
                            },
                        }
                    },
                    {
                        "label": "NonAnimatedCardBlockImage",
                        "name": "non_animate_card_blockImage",
                        "content": {
                            "left_content": {
                                "image_id": nonAnimatedCardBlockImage?.image_id,
                                "path": nonAnimatedCardBlockImage?.path,
                                "name": nonAnimatedCardBlockImage?.name
                            },
                            "right_content": {
                                "heading": nonAnimatedCardBlock?.['heading'],
                                "description": nonAnimatedCardBlock?.['description'] || "",
                                "personal_description": nonAnimatedCardBlock?.['personal_description'] || "",
                                "personal_button_label": nonAnimatedCardBlock?.['personal_button_label'] || "",
                                "personal_button_url": nonAnimatedCardBlock?.['personal_button_url'] || "",
                                "business_description": nonAnimatedCardBlock?.['business_description'] || "",
                                "business_button_label": nonAnimatedCardBlock?.['business_button_label'] || "",
                                "business_button_url": nonAnimatedCardBlock?.['business_button_url'] || "",
                            },
                        }
                    },
                    {
                        "label": "BlankCardSectionTitle",
                        "name": "blankCardSectionTitle",
                        "title": IndividualAndBoxSetTitle?.['title']
                    },
                    {
                        "label": "Individual Text And Image",
                        "name": "individual_text_and_image",
                        "content": {
                            "left_content": {
                                "heading": individualTextAndImageBlock?.['heading'],
                                "description": individualTextAndImageBlock?.['description'] || "",
                                "button_label": individualTextAndImageBlock?.['button_label'] || "",
                                "button_url": individualTextAndImageBlock?.['button_url'] || "",
                            },
                            "right_content": {
                                "image_id": individualTextAndImageBlockImage?.image_id,
                                "path": individualTextAndImageBlockImage?.path,
                                "name": individualTextAndImageBlockImage?.name
                            },
                        }
                    },
                    {
                        "label": "Box set Image And Image",
                        "name": "box_set_image_and_text",
                        "content": {
                            "left_content": {
                                "image_id": ImageAndTextBlockImage?.image_id,
                                "path": ImageAndTextBlockImage?.path,
                                "name": ImageAndTextBlockImage?.name
                            },
                            "right_content": {
                                "heading": ImageAndTextBlock?.['heading'],
                                "description": ImageAndTextBlock?.['description'] || "",
                                "personal_description": ImageAndTextBlock?.['personal_description'] || "",
                                "personal_button_label": ImageAndTextBlock?.['personal_button_label'] || "",
                                "personal_button_url": ImageAndTextBlock?.['personal_button_url'] || "",
                            },
                        }
                    },
                    {
                        "label": "About",
                        "name": "about",
                        "content": {
                            "left_content": {
                                "heading": aboutBlockLeftContent?.['heading'],
                                "sub_heading": aboutBlockLeftContent?.['sub_heading'] || "",
                                "description": aboutBlockLeftContent?.['description'] || "",
                                "button_label": aboutBlockLeftContent?.['button_label'] || "",
                                "button_url": aboutBlockLeftContent?.['button_url'] || "",
                            },
                            "right_content": {
                                "heading": aboutBlockRightContent?.['heading'],
                                "button_label": aboutBlockRightContent?.['button_label'] || "",
                                "button_url": aboutBlockRightContent?.['button_url'] || "",
                                "logo": {
                                    "image_id": aboutBlockLogoImage?.image_id,
                                    "path": aboutBlockLogoImage?.path,
                                    "name": aboutBlockLogoImage?.name
                                },
                                "background_image": {
                                    "image_id": aboutBlockBackgroundImage?.image_id,
                                    "path": aboutBlockBackgroundImage?.path,
                                    "name": aboutBlockBackgroundImage?.name
                                }
                            }
                        }
                    },
                    {
                        "label": "Help",
                        "name": "help"
                    }
                ]
            }
        }

        console.log("pay_load", pay_load)
        callAPI("/admin/settings/pages/save",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                },
                body: JSON.stringify(pay_load)
            }, navigate, setLoading, setStore)
            .then(data => {
                if (data.success) {
                    setAlert({ text: "Page saved successfully", type: "success" })
                } else {
                    // console.log("data.errors", data.errors)
                }
            })

    }

    let select_store = store?.id == null || store?.id == undefined || store?.id == 0 || store?.name == "Global Admin" ? true : false

    const limits = Object.keys(page_labels?.catalog_block?.limit?.limits)?.map((name) => {
        const value = page_labels?.catalog_block?.limit?.limits[name];
        return `${name},${value}`;
    });

    console.log("bannerBlock",bannerBlock);
    

    return (
        <Layout>

            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <BreadCrumb />
                {!select_store && <Button className={'btn btn-primary'} value={page_labels?.btn} onClick={() => onSave()} />}
            </div>

            {alert?.text ?
                <div className="text-center">
                    <Alert text={alert.text} type={alert.type} setAlert={setAlert} />
                </div>
                : ''}

            {select_store ?
                <div className="card card-default">
                    <div className={`card-body select_store`}>
                        <p className="text-center">{select_store_message?.select_store}</p>
                    </div>
                </div>
                :
                <>
                    <div className="col-lg-10">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.banner_block?.title}</h2>
                            </div>
                            
                            <div className="card-body">
                                <h5 className="mb-3">{page_labels?.banner_block?.left_content?.title}</h5>
                                <div className="col-lg-10">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.banner_block?.left_content?.heading}</label>
                                        <input type="text" className="form-control" name='heading' value={bannerBlock?.['heading']} onChange={onChangeBannerBlockField} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.banner_block?.left_content?.sub_heading}</label>
                                        <input type="text" className="form-control" name='sub_heading' value={bannerBlock?.['sub_heading']} onChange={onChangeBannerBlockField} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="form-group mb-2 page_2_portrait">
                                        <label className="form-label">{page_labels?.banner_block?.left_content?.description}</label>
                                        <TextEditor onChangeTextEditorValue={onChangeBannerBlockDescription} productDescription={bannerDescription??bannerBlock?.['description']}/>
                                        {/* <textarea type="text" className="form-control" rows="4" cols="50" name="description" value={bannerBlock?.['description']} onChange={onChangeBannerBlockField} /> */}
                                    </div>
                                </div>
                                <div className="col-lg-12 row">
                                    <div className="form-group col-lg-5">
                                        <label className="form-label">{page_labels?.banner_block?.left_content?.button_label}</label>
                                        <input type="text" className="form-control" name="button_label" value={bannerBlock?.['button_label']} onChange={onChangeBannerBlockField} />
                                    </div>
                                    <div className="form-group col-lg-5">
                                        <label className="form-label">{page_labels?.banner_block?.left_content?.button_url}</label>
                                        <input type="text" className="form-control" name="button_url" value={bannerBlock?.['button_url']} onChange={onChangeBannerBlockField} />
                                    </div>
                                </div>

                                <h5 className="mb-3">{page_labels?.banner_block?.right_image?.title}</h5>
                                <div className="col-lg-6">
                                    <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={bannerBlockImage} userProfile={false} style={{ width: "79%" }} onOpen={onOpenBannerImageBlockSelector} deleteDisable={true} selectedImageInfo={bannerBlockImage} onRemoveImageIds={onRemoveBannerBlockImage} />
                                </div>
                                <div className="form-group col-lg-5 mt-4">
                                        <label className="form-label">{page_labels?.banner_block?.right_image?.button_url}</label>
                                        <input type="text" className="form-control" name="img_button_url" value={bannerBlock?.['img_button_url']} onChange={onChangeBannerBlockField} />
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-10 mt-5 mb-5">
                        {/* Card for category status */}
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.catalog_block?.title}</h2>
                            </div>
                            <div className="col-lg-10">
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.catalog_block?.heading}</label>
                                        <input type="text" className="form-control" name='heading' value={catalogtBlock?.["heading"]} onChange={onChangeCatalogtBlockField} />
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-lg-5">
                                            <label className="form-label">{page_labels?.catalog_block?.collection?.title}</label>
                                            {categories?.length != 0 ?
                                                <select className="form-control" name="collection_id" value={catalogtBlock?.["collection_id"]} onChange={onChangeCatalogtBlockField}>
                                                    <option hidden>{page_labels?.catalog_block?.collection.select_collection}</option>
                                                    {categories?.map((category, index) => {
                                                        return <option value={category?.category_id} data-name={category?.name} key={index}>{category?.name}</option>
                                                    })}
                                                </select>
                                                :
                                                <p>{home_page_message?.collection_not_available} <button className="btn btn-primary ml-5" onClick={() => navigate("/admin/category/new")}>{page_labels?.catalog_block?.limit?.btn}</button></p>
                                            }
                                        </div>
                                        {categories?.length != 0 &&
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.catalog_block?.limit?.title}</label>
                                                <select className="form-control" name="limit" value={catalogtBlock?.["limit"]} onChange={onChangeCatalogtBlockField}>
                                                    <option hidden>{page_labels?.catalog_block?.limit?.select_limit}</option>
                                                    {limits &&
                                                        limits?.map((optionString, index) => {
                                                            const [name, value] = optionString.split(',');
                                                            return <option value={name} key={index}>{value}</option>;
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-10 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.personalized_card_block?.title_2}</h2>
                            </div>
                            <div className="card-body">
                                {/* <div className="col-lg-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.personalized_card_block?.title}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            value={animatedAndNonAnimatedTitle?.["title"]}
                                            onChange={onChangeAnimatedAndNonAnimatedTitleField}
                                        />

                                    </div>

                                </div> */}
                                <div className="col-lg-12 row">
                                <div className="col-lg-6">
                                        <h2 className="form-label">{page_labels?.personalized_card_block?.left_image?.title}</h2>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} userProfile={false} style={{ width: "85%" }} onOpen={onOpenPersonalizedTextAndImageBlockSelector} onRemoveImageIds={onRemovePersonalizedTextAndImageBlockImage} deleteDisable={true} selectedImageInfo={personalizedtextAndImageBlockImage} />
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="mb-3">{page_labels?.personalized_card_block?.right_content?.title}</h5>
                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.personalized_card_block?.right_content?.heading}</label>
                                                <input type="text" className="form-control" name="heading" value={personalizedtextAndImageBlock?.['heading']} onChange={onChangePersonalizedTextAndImageBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.personalized_card_block?.right_content?.description}</label>
                                                <textarea className="form-control" rows="4" cols="50" name="description" value={personalizedtextAndImageBlock?.['description']} onChange={onChangePersonalizedTextAndImageBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 row">
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.personalized_card_block?.right_content?.button_label}</label>
                                                <input type="text" className="form-control" name="button_label" value={personalizedtextAndImageBlock?.['button_label']} onChange={onChangePersonalizedTextAndImageBlockField} />
                                            </div>
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.personalized_card_block?.right_content?.button_url}</label>
                                                <input type="text" className="form-control" name="button_url" value={personalizedtextAndImageBlock?.["button_url"]} onChange={onChangePersonalizedTextAndImageBlockField} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 {/* jkhlkg */}
                    <div className="col-lg-10 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.text_and_image_block?.title_2}</h2>
                            </div>
                            <div className="card-body">
                                <div className="col-lg-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.text_and_image_block?.title}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            value={animatedAndNonAnimatedTitle?.["title"]}
                                            onChange={onChangeAnimatedAndNonAnimatedTitleField}
                                        />

                                    </div>

                                </div>
                                <div className="col-lg-12 row">
                                    <div className="col-lg-6">
                                        <h5 className="mb-3">{page_labels?.text_and_image_block?.left_content?.title}</h5>
                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.heading}</label>
                                                <input type="text" className="form-control" name="heading" value={animatedtextAndImageBlock?.['heading']} onChange={onChangeAnimatedTextAndImageBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.description}</label>
                                                <textarea className="form-control" rows="4" cols="50" name="description" value={animatedtextAndImageBlock?.['description']} onChange={onChangeAnimatedTextAndImageBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 row">
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.button_label}</label>
                                                <input type="text" className="form-control" name="button_label" value={animatedtextAndImageBlock?.['button_label']} onChange={onChangeAnimatedTextAndImageBlockField} />
                                            </div>
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.button_url}</label>
                                                <input type="text" className="form-control" name="button_url" value={animatedtextAndImageBlock?.["button_url"]} onChange={onChangeAnimatedTextAndImageBlockField} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <h2 className="form-label">{page_labels?.text_and_image_block?.right_image?.title}</h2>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={animatedtextAndImageBlockImage} userProfile={false} style={{ width: "85%" }} onOpen={onOpenAnimatedTextAndImageBlockSelector} onRemoveImageIds={onRemoveAnimatedTextAndImageBlockImage} deleteDisable={true} selectedImageInfo={animatedtextAndImageBlockImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-10 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.non_animated_card_block?.title}</h2>
                            </div>
                            <div className="card-body">

                                <div className="col-lg-12 row">

                                    <div className="col-lg-6">
                                        <label className="form-label">{page_labels?.non_animated_card_block?.left_image?.title}</label>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={nonAnimatedCardBlockImage} userProfile={false} style={{ width: "85%" }} onOpen={onOpenNonAnimatedCardBlockSelector} onRemoveImageIds={onRemoveNonAnimatedCardBlockImage} deleteDisable={true} selectedImageInfo={nonAnimatedCardBlockImage} />
                                    </div>

                                    <div className="col-lg-6">

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.heading}</label>
                                                <input type="text" className="form-control" name="heading" value={nonAnimatedCardBlock?.["heading"]} onChange={onChangeNonAnimatedCardBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.description}</label>
                                                <textarea className="form-control" rows="6" cols="50" name="description" value={nonAnimatedCardBlock?.["description"]} onChange={onChangeNonAnimatedCardBlockField} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 row mt-5">
                                        <div className="col-lg-6">
                                            <div className="col-lg-10">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.personal_description}</label>
                                                    <textarea className="form-control" rows="6" cols="50" name="personal_description" value={nonAnimatedCardBlock?.["personal_description"]} onChange={onChangeNonAnimatedCardBlockField} />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 row">
                                                <div className="form-group col-lg-5">
                                                    <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.button_label}</label>
                                                    <input type="text" className="form-control" name="personal_button_label" value={nonAnimatedCardBlock?.["personal_button_label"]} onChange={onChangeNonAnimatedCardBlockField} />
                                                </div>
                                                <div className="form-group col-lg-5">
                                                    <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.button_url}</label>
                                                    <input type="text" className="form-control" name="personal_button_url" value={nonAnimatedCardBlock?.["personal_button_url"]} onChange={onChangeNonAnimatedCardBlockField} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="col-lg-10">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.business_description}</label>
                                                    <textarea className="form-control" rows="6" cols="50" name="business_description" value={nonAnimatedCardBlock?.["business_description"]} onChange={onChangeNonAnimatedCardBlockField} />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 row">
                                                <div className="form-group col-lg-5">
                                                    <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.button_label}</label>
                                                    <input type="text" className="form-control" name="business_button_label" value={nonAnimatedCardBlock?.["business_button_label"]} onChange={onChangeNonAnimatedCardBlockField} />
                                                </div>
                                                <div className="form-group col-lg-5">
                                                    <label className="form-label">{page_labels?.non_animated_card_block?.right_content?.button_url}</label>
                                                    <input type="text" className="form-control" name="business_button_url" value={nonAnimatedCardBlock?.["business_button_url"]} onChange={onChangeNonAnimatedCardBlockField} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-10 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.text_and_image_block?.title_1}</h2>
                            </div>

                            <div className="card-body">
                                <div className="col-lg-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.text_and_image_block?.title}</label>
                                        <input type="text" className="form-control" name="title" value={IndividualAndBoxSetTitle?.["title"]} onChange={onChangeIndividualAndBoxSetTitleField} />
                                    </div>
                                </div>

                                <div className="col-lg-12 row">

                                    <div className="col-lg-6">
                                        <h5 className="mb-3">{page_labels?.text_and_image_block?.left_content?.title}</h5>
                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.heading}</label>
                                                <input type="text" className="form-control" name="heading" value={individualTextAndImageBlock?.["heading"]} onChange={onChangeIndividualTextAndImageBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.description}</label>
                                                <textarea className="form-control" rows="4" cols="50" name="description" value={individualTextAndImageBlock?.["description"]} onChange={onChangeIndividualTextAndImageBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 row">
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.button_label}</label>
                                                <input type="text" className="form-control" name="button_label" value={individualTextAndImageBlock?.["button_label"]} onChange={onChangeIndividualTextAndImageBlockField} />
                                            </div>
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.text_and_image_block?.left_content?.button_url}</label>
                                                <input type="text" className="form-control" name="button_url" value={individualTextAndImageBlock?.["button_url"]} onChange={onChangeIndividualTextAndImageBlockField} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <h2 className="form-label">{page_labels?.text_and_image_block?.right_image?.title}</h2>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={individualTextAndImageBlockImage} userProfile={false} style={{ width: "85%" }} onOpen={onOpenIndividualTextAndImageBlockSelector} onRemoveImageIds={onRemoveIndividualTextAndImageBlockImage} deleteDisable={true} selectedImageInfo={individualTextAndImageBlockImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-10 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.image_and_text_block?.title}</h2>
                            </div>
                            <div className="card-body">

                                <div className="col-lg-12 row">

                                    <div className="col-lg-6">
                                        <label className="form-label">{page_labels?.image_and_text_block?.right_image?.title}</label>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={ImageAndTextBlockImage} userProfile={false} style={{ width: "85%" }} onOpen={onOpenImageAndTextBlockSelector} onRemoveImageIds={onRemoveImageAndTextBlockImage} deleteDisable={true} selectedImageInfo={ImageAndTextBlockImage} />
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="mb-3">{page_labels?.image_and_text_block?.right_content?.title}</h5>
                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.image_and_text_block?.right_content?.heading}</label>
                                                <input type="text" className="form-control" name="heading" value={ImageAndTextBlock?.["heading"]} onChange={onChangeImageAndTextBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.image_and_text_block?.right_content?.description}</label>
                                                <textarea className="form-control" rows="4" cols="50" name="description" value={ImageAndTextBlock?.["description"]} onChange={onChangeImageAndTextBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-10">
                                            <div className="form-group mb-4">
                                                <label className="form-label">{page_labels?.image_and_text_block?.right_content?.personal_description}</label>
                                                <textarea className="form-control" rows="4" cols="50" name="personal_description" value={ImageAndTextBlock?.["personal_description"]} onChange={onChangeImageAndTextBlockField} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 row">
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.image_and_text_block?.right_content?.button_label}</label>
                                                <input type="text" className="form-control" name="personal_button_label" value={ImageAndTextBlock?.["personal_button_label"]} onChange={onChangeImageAndTextBlockField} />
                                            </div>
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">{page_labels?.image_and_text_block?.right_content?.button_url}</label>
                                                <input type="text" className="form-control" name="personal_button_url" value={ImageAndTextBlock?.["personal_button_url"]} onChange={onChangeImageAndTextBlockField} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-10 mt-5">
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom">
                                <h2>{page_labels?.about_block?.title}</h2>
                            </div>
                            <div className="card-body">
                                <h5 className="mb-3">{page_labels?.about_block?.left_content?.title}</h5>
                                <div className="col-lg-10">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.about_block?.left_content?.heading}</label>
                                        <input type="text" className="form-control" name="heading" value={aboutBlockLeftContent?.["heading"]} onChange={onChangeAboutBlockLeftField} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.about_block?.left_content?.sub_heading}</label>
                                        <input type="text" className="form-control" name="sub_heading" value={aboutBlockLeftContent?.["sub_heading"]} onChange={onChangeAboutBlockLeftField} />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="form-group mb-4">
                                        <label className="form-label">{page_labels?.about_block?.left_content?.description}</label>
                                        <textarea type="text" className="form-control " rows="4" cols="50" name="description" value={aboutBlockLeftContent?.["description"]} onChange={onChangeAboutBlockLeftField} />
                                    </div>
                                </div>
                                <div className="col-lg-12 row">
                                    <div className="form-group col-lg-5">
                                        <label className="form-label">{page_labels?.about_block?.left_content?.button_label}</label>
                                        <input type="text" className="form-control" name="button_label" value={aboutBlockLeftContent?.["button_label"]} onChange={onChangeAboutBlockLeftField} />
                                    </div>
                                    <div className="form-group col-lg-5">
                                        <label className="form-label">{page_labels?.about_block?.left_content?.button_url}</label>
                                        <input type="text" className="form-control" name="button_url" value={aboutBlockLeftContent?.["button_url"]} onChange={onChangeAboutBlockLeftField} />
                                    </div>
                                </div>

                                <h5 className="mb-3">{page_labels?.about_block?.right_content?.title}</h5>
                                <div className="col-lg-12 row">
                                    <div className="col-lg-10">
                                        <div className="form-group mb-4">
                                            <label className="form-label">{page_labels?.about_block?.right_content?.heading}</label>
                                            <input type="text" className="form-control" name="heading" value={aboutBlockRightContent?.["heading"]} onChange={onChangeAboutBlockRightField} />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-5">
                                        <label className="form-label">{page_labels?.about_block?.right_content?.button_label}</label>
                                        <input type="text" className="form-control" name="button_label" value={aboutBlockRightContent?.["button_label"]} onChange={onChangeAboutBlockRightField} />
                                    </div>
                                    <div className="form-group col-lg-5">
                                        <label className="form-label">{page_labels?.about_block?.right_content?.button_url}</label>
                                        <input type="text" className="form-control" name="button_url" value={aboutBlockRightContent?.["button_url"]} onChange={onChangeAboutBlockRightField} />
                                    </div>
                                </div>
                                <div className="col-lg-12 images_preview_block_wrapper">
                                    <div className="images_preview_block">
                                        <label className="form-label">{page_labels?.about_block?.right_content?.logo}</label>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={aboutBlockLogoImage} userProfile={false} style={{ width: "85%" }} onOpen={onOpenaboutBlockLogoImageBlockSelector} onRemoveImageIds={onRemoveAboutBlockLogo} deleteDisable={true} selectedImageInfo={aboutBlockLogoImage} />
                                    </div>
                                    <div className="images_preview_block">
                                        <label className="form-label">{page_labels?.about_block?.right_content?.background_image}</label>
                                        <ImagesPreview acceptMultipleFiles={false} featuredImage={true} savedImages={aboutBlockBackgroundImage} userProfile={false} style={{ width: "85%" }} onOpen={onOpenaboutBlockBackgroundImageImageBlockSelector} onRemoveImageIds={onRemoveAboutBlockBackgroundImage} deleteDisable={true} selectedImageInfo={aboutBlockBackgroundImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="text-right">
                {!select_store && <Button className={'btn btn-primary'} value={'Save'} onClick={() => onSave()} />}
            </div>
            <ImageSelector Config={config} onClose={onClose} onSelectImageInfo={onSelectImages} />
        </Layout>
    )
}

export default HomePage