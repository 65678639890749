import React, { useState, useEffect } from "react";
import BreadCrumb from '../../../Components/Global/BreadCrumb/BreadCrumb';
import { Pie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line'
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../Constants/Errors";
import { dashboard_page_labels } from "../../../Constants/Labels";
import { callAPI, useStore, useUser } from "../../../Utils/utils";
import ErrorMessage from "../../Global/ErrorMessage"
import Message from "../../Message/Message";
import Loader from "../../Global/Loader/Loader";
import moment from "moment";
import "./StoreAdminDashboard.css";

const StoreAdminDashboard = () => {

    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [sales, setSales] = useState();
    const [donations, setDonations] = useState(0);
    const [orders, setOrders] = useState();
    const [errors, setErrors] = useState();
    const { store, setStore } = useStore();
    const [payLoad, setPayLoad] = useState();
    const [products, setProducts] = useState();
    const [customers, setCustomers] = useState();
    const [loading, setLoading] = useState(false);
    const [formValues, setformValues] = useState({});
    const [categories, setCategories] = useState();
    const [ordersStatus, setOrdersStatus] = useState();
    const [averageOrderValue, setaverageOrderValue] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    useEffect(() => {
        callAPI("/admin/dashboard/top-five-categories",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setCategories(data?.records)
                } else {
                    setErrors(data?.errors)
                    // console.log('res.error', data.error)
                }
            })
    }, [store?.id, payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/top-five-products",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setProducts(data?.records)
                } else {
                    // console.log('res.error', data.error)
                }
            }
            )
    }, [store?.id, payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/store-orders",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Orders: obj?.order_count
                        }))
                    };
                    setOrders(updatedResponse)
                } else {
                    // console.log('res.error', data.error)
                }
            }
            )
    }, [store?.id, payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/store-sales",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Sales: obj?.sales
                        }))
                    };
                    setSales(updatedResponse)
                    var total_donations = 0
                    if(data?.total_donation){
                        total_donations = data?.total_donation == "null" || data?.total_donation == "Null" || data?.total_donation == null || data?.total_donation == undefined ? 0 : data?.total_donation
                        setDonations(total_donations)
                    } else {
                        setDonations(total_donations)   
                    }
                } else {
                    // console.log('res.error', data.error)
                }
            }
            )
    }, [store?.id, payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/store-average-order-value",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            AverageOrderValue: obj?.average_order_value
                        }))
                    };
                    setaverageOrderValue(updatedResponse)
                } else {
                    // console.log('res.error', data.error)
                }
            }
            )
    }, [store?.id, payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/store-customer-count",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    const updatedResponse = {
                        ...data,
                        records: data?.records?.map(obj => ({
                            Date: obj?.date,
                            Customers: obj?.customers
                        }))
                    };
                    setCustomers(updatedResponse)
                } else {
                    // console.log('res.error', data.error)
                }
            }
            )
    }, [store?.id, payLoad])

    useEffect(() => {
        callAPI("/admin/dashboard/orders-by-status",
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': user?.auth_token
                }, body: JSON.stringify(payLoad)

            }, navigate, setLoading)
            .then(data => {
                // console.log("api response " + JSON.stringify(data))
                if (data.success == true) {
                    setOrdersStatus(data)
                } else {
                    // console.log('res.error', data.error)
                }
            }
            )
    }, [store?.id, payLoad])

    useEffect(() => {
        if (formValues?.time_window != "custom") {
            setPayLoad({
                "store_id": store?.id,
                "time_window": {
                    "window_type": formValues?.time_window ? formValues?.time_window : "this-week"
                }
            })
        }

    }, [formValues, store?.id])

    var categories_by_date = categories
    // Convert the "string" values to numbers
    categories_by_date?.forEach(categories_by_date => {
        categories_by_date.category_count = Number(categories_by_date?.category_count);
    });

    var customers_by_date = customers?.records
    // Convert the "string" values to numbers
    customers_by_date?.forEach(customers_by_date => {
        customers_by_date.customers = Number(customers_by_date?.customers);
    });

    var orders_by_date = orders?.records
    // Convert the "string" values to numbers
    orders_by_date?.forEach(orders_by_date => {
        orders_by_date.order_count = Number(orders_by_date?.order_count);
    });

    var sales_by_date = sales?.records
    // Convert the "string" values to numbers
    sales_by_date?.forEach(sales_by_date => {
        sales_by_date.sales = Number(sales_by_date?.sales);
    });

    var averageOrderValue_by_date = averageOrderValue?.records
    // Convert the "string" values to numbers
    averageOrderValue_by_date?.forEach(averageOrderValue_by_date => {
        averageOrderValue_by_date.average_order_value = Number(averageOrderValue_by_date?.average_order_value);
    });

    var customers_by_date = customers?.records
    // Convert the "string" values to numbers
    customers_by_date?.forEach(customers_by_date => {
        customers_by_date.customers = Number(customers_by_date?.customers);
    });

    const statusCounts = {};

    // Iterate over the records to get the counts for each status
    ordersStatus?.records?.forEach(record => {
        const status = record?.value;
        const count = parseInt(record?.count);
        statusCounts[status] = count;
    });


    // store onchage value in state
    const onChangeField = (event) => {
        setformValues({ ...formValues, [event.target.name]: event.target.value })
        setShowErrorMessage(false)
        setErrors([])
    }

    const onSelectedDate = () => {
        setShowErrorMessage(true)
        const time_window = {

            "window_type": "custom",
            "from_date": formValues["from_date"] ? moment(formValues["from_date"]).format("YYYY-MM-DD") : "",
            "to_date": formValues["to_date"] ? moment(formValues["to_date"]).format("YYYY-MM-DD") : ""
        };

        setPayLoad({
            "store_id": store?.id,
            "time_window": time_window
        });
    }

    var total_orders = orders?.total_orders ? orders?.total_orders : 0
    var total_sales = sales?.total_sales ? sales?.total_sales : 0
    var average_order_values = averageOrderValue?.total_average_order_value ? averageOrderValue?.total_average_order_value : 0
    var total_customers = customers?.total_customers ? customers?.total_customers : 0

    var sales_by_date_convertedData = []
    if (sales_by_date?.length != 0 && sales_by_date?.length != undefined) {
        sales_by_date_convertedData = [
            {
                id: 'Sales',
                data: sales_by_date?.map(item => ({
                    x: item?.Date,
                    y: item?.Sales
                }))
            }
        ];
    }

    var average_order_values_convertedData = []
    if (averageOrderValue_by_date?.length != 0 && averageOrderValue_by_date?.length != undefined) {
        average_order_values_convertedData = [
            {
                id: 'average_order_value',
                data: averageOrderValue_by_date?.map(item => ({
                    x: item?.Date,
                    y: item?.AverageOrderValue
                }))
            }
        ];
    }

    var categories_by_date_pie_chart = []
    if (categories_by_date?.length != 0 && categories_by_date?.length != undefined) {
        categories_by_date_pie_chart = categories_by_date?.map(item => ({
            id: item?.name,
            label: item?.name,
            value: item?.category_count
        }));
    }

    
    return (
        <>
            {loading &&
                <Loader className={"dashboard_page_loader"} />
            }

            <div className="breadcrumb-wrapper breadcrumb-contacts">
                <div className="d-flex flex-column">
                    <BreadCrumb />
                </div>
                <div className="date_and_dropdown_wrapper">
                    {formValues?.time_window == "custom" &&
                        <div className=" custome_address_wrapper">
                            <div className="custome_address">
                                <div className="from_date">
                                    <label className="form-label mandatory_field">{dashboard_page_labels?.global_and_store_custom?.from_date}</label>
                                    <input type="date" placeholder="from" className="form-control" name='from_date' max={formValues['to_date']} value={formValues['from_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'from_date', errors })] || ErrorMessage({ fieldName: 'from_date', errors })} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                                <div className="to_date">
                                    <label className="form-label mandatory_field">{dashboard_page_labels?.global_and_store_custom?.to_date}</label>
                                    <input type="date" className="form-control" name='to_date' placeholder="To date" min={formValues['from_date']} value={formValues['to_date'] || ''} onChange={onChangeField} />
                                    <div className="errorMessage">
                                        <Message text={errorMessages[ErrorMessage({ fieldName: 'to_date', errors })] || ErrorMessage({ fieldName: 'to_date', errors })} type={"errorMsg"} style={{ marginTop: "17px" }} showMessage={showErrorMessage} />
                                    </div>
                                </div>
                                <div className="apply_btn">
                                    <button className="btn btn-primary" onClick={onSelectedDate}>{dashboard_page_labels?.global_and_store_custom?.btn_label}</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="drop_down">
                        <select className="form-control" name='time_window' onChange={onChangeField} value={formValues['time_window'] || "this-week"} >
                            {dashboard_page_labels?.global_and_store_options?.map((option, index) => (
                                <option key={index} value={option?.value}>{option?.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {!loading &&
                <div className="orderStatusCardWrapper">
                    <div className="orderchartSection">
                        <div className="orderchartBlock">
                            <div className="orderStatusIcon">
                                <i className="mdi  mdi-cart-outline" />
                            </div>
                            <div className="orderStatusText">
                                <h3>{ordersStatus?.["total_orders"] ? ordersStatus?.["total_orders"] : 0}</h3>
                                <p>{dashboard_page_labels?.store?.container_block?.total_orders}</p>
                            </div>
                        </div>
                    </div>

                    <div className="orderchartSection">
                        <div className="orderchartBlock">
                            <div className="orderStatusIcon">
                                <i className="mdi mdi-cart-plus" />
                            </div>
                            <div className="orderStatusText">
                                <h3>{statusCounts?.['Confirmed'] ? statusCounts?.['Confirmed'] : 0}</h3>
                                <p>{dashboard_page_labels?.store?.container_block?.confirmed}</p>
                            </div>
                        </div>
                    </div>

                    <div className="orderchartSection">
                        <div className="orderchartBlock">
                            <div className="orderStatusIcon">
                                <i className="mdi mdi-cart-arrow-down " />
                            </div>
                            <div className="orderStatusText">
                                <h3>{statusCounts?.['In Process'] ? statusCounts?.['In Process'] : 0}</h3>
                                <p>{dashboard_page_labels?.store?.container_block?.in_process}</p>
                            </div>
                        </div>
                    </div>
                    <div className="orderchartSection">
                        <div className="orderchartBlock">
                            <div className="orderStatusIcon">
                                <i className="mdi mdi-cart-arrow-right" />
                            </div>
                            <div className="orderStatusText">
                                <h3>{statusCounts?.['Shipped'] ? statusCounts?.['Shipped'] : 0}</h3>
                                <p>{dashboard_page_labels?.store?.container_block?.Shipped}</p>
                            </div>
                        </div>
                    </div>
                </div>}

            {!loading &&
                <div className="mt-5">
                    <div className="chartWrapper">
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.store?.orders} : {total_orders}</span>
                                {orders_by_date?.length != 0 && orders_by_date?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveBar
                                            data={orders_by_date}
                                            keys={['Orders']}
                                            indexBy="Date"
                                            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                                            colors="#005460"
                                            labelTextColor="#FFFFFF"
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            axisBottom={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                // legend: 'Date',
                                                // itemWidth: 100,
                                                legendPosition: 'middle',
                                                legendOffset: 40,
                                                format: () => '', // Hide the tick labels
                                                tickValues: 5, // Adjust the number of ticks displayed
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                                barSize: 5
                                            }}
                                        />
                                    </div>

                                }
                            </div>
                        </div>

                        <div className="card">
                            <div className="individualChartBlock p-3 "  >
                                <span className="chartTitle">{dashboard_page_labels?.store?.sales} : {total_sales}</span>
                                <span className="chartTitle">{dashboard_page_labels?.store?.donation} : {donations}</span>
                                {sales_by_date_convertedData?.length != 0 && sales_by_date_convertedData?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveLine
                                            data={sales_by_date_convertedData}
                                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                            xScale={{ type: 'point' }}
                                            yScale={{
                                                type: 'linear',
                                                min: 'auto',
                                                max: 'auto',
                                                stacked: true,
                                                reverse: false
                                            }}
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            yFormat=" >-.2f"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                enable: true,
                                                tickSize: 0,
                                                tickPadding: 0,
                                                tickRotation: 0,
                                                legend: '',
                                                legendOffset: 36,
                                                legendPosition: 'middle',
                                                format: () => '', // Empty string to hide tick text
                                            }}
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            colors="#005460"
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            useMesh={true}
                                            legends={[
                                                {
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 100,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: 'circle',
                                                    symbolBorderColor: "#005460",
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ],
                                                }
                                            ]}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="chartWrapper">
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.store?.average_order_value} : {average_order_values}</span>
                                {average_order_values_convertedData?.length != 0 && average_order_values_convertedData?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>

                                        <ResponsiveLine
                                            data={average_order_values_convertedData}
                                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                            xScale={{ type: 'point' }}
                                            yScale={{
                                                type: 'linear',
                                                min: 'auto',
                                                max: 'auto',
                                                stacked: true,
                                                reverse: false
                                            }}
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            yFormat=" >-.2f"
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                enable: true,
                                                tickSize: 0,
                                                tickPadding: 0,
                                                tickRotation: 0,
                                                legend: '',
                                                legendOffset: 36,
                                                legendPosition: 'middle',
                                                format: () => '', // Empty string to hide tick text
                                            }}
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            colors="#005460"
                                            pointSize={10}
                                            pointColor={{ theme: 'background' }}
                                            pointBorderWidth={2}
                                            pointBorderColor={{ from: 'serieColor' }}
                                            pointLabelYOffset={-12}
                                            useMesh={true}
                                            legends={[
                                                {
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 98,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: 'circle',
                                                    symbolBorderColor: "#005460",
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ],
                                                }
                                            ]}
                                        />


                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card">
                            <div className="individualChartBlock p-3">
                                <span className="chartTitle">{dashboard_page_labels?.store?.customers} : {total_customers} </span>
                                {customers_by_date?.length != 0 && customers_by_date?.length != undefined &&
                                    <div style={{ width: "100%", height: "400px" }}>
                                        <ResponsiveBar
                                            data={customers_by_date}
                                            keys={['Customers']}
                                            indexBy="Date"
                                            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                                            colors="#005460"
                                            enableGridY={false} // Disable vertical grid lines
                                            enableGridX={false} // Disable horizontal grid lines
                                            labelTextColor="#FFFFFF"
                                            axisLeft={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                tickFormat: (value) => {
                                                    if (value < 0) {
                                                        return `${Math.abs(value)}+`;
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40,
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                            }}
                                            axisBottom={{
                                                tickSize: 0,
                                                tickPadding: 10,
                                                tickRotation: 0,
                                                // legend: 'Date',
                                                // itemWidth: 100,

                                                legendPosition: 'middle',
                                                legendOffset: 40,
                                                format: () => '', // Hide the tick labels
                                                tickValues: 5, // Adjust the number of ticks displayed
                                                style: {
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    fill: '#FF0000', // Specify the color for the axis labels
                                                },
                                                barSize: 5

                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="chartWrapper">
                        {categories_by_date_pie_chart?.length == 0 ?
                            "" :
                            <div className="card">
                                <span className="m-5">{dashboard_page_labels?.store?.categories}</span>
                                <div style={{ width: "100%" }} className="d-flex flex-column justify-content-center align-items-center">
                                    <Pie
                                        width={300}
                                        height={300}
                                        data={categories_by_date_pie_chart}
                                        innerRadius={0.5}
                                        padAngle={3}
                                        activeOuterRadiusOffset={8}
                                        enableArcLinkLabels={false}
                                        cornerRadius={3}
                                        colors={{ scheme: 'nivo' }}
                                        borderWidth={1}
                                        enableGridY={false} 
                                        enableGridX={false} 
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        radialLabelsSkipAngle={10}
                                        radialLabelsTextXOffset={6}
                                        radialLabelsTextColor="#333333"
                                        radialLabelsLinkOffset={10}
                                        radialLabelsLinkDiagonalLength={16}
                                        radialLabelsLinkHorizontalLength={24}
                                        radialLabelsLinkStrokeWidth={1}
                                        radialLabelsLinkColor={{ from: 'color' }}
                                        sliceLabelsSkipAngle={10}
                                        sliceLabelsTextColor="#333333"
                                        sliceLabel={({ value }) => `${value}`}
                                        animate={false}
                                        isInteractive={false} 
                                        arcLinkLabelsSkipAngle={5}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{
                                            from: 'color',
                                            modifiers: [['darker', 2]]
                                        }}
                                    />

                                    <div className="mt-5 mb-5">
                                        {categories_by_date_pie_chart.map((slice, index) => (
                                            <div key={index}>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '12px',
                                                        height: '12px',
                                                        backgroundColor: slice.color,
                                                        marginRight: '4px',
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        color: '#333333',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    {slice.id}:
                                                </span>
                                                <span>{slice.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }

                        {products?.length == 0 ? "" :
                            <div className="card">
                                <div className="productTable">
                                    <span>{dashboard_page_labels?.store?.products_table?.title}</span>
                                    <div className="tableWrapper">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{dashboard_page_labels?.store?.products_table?.product_image}</th>
                                                    <th>{dashboard_page_labels?.store?.products_table?.product}</th>
                                                    <th>{dashboard_page_labels?.store?.products_table?.count}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products?.map((product, index) => {
                                                    // console.log("product",product)
                                                    return (
                                                        <tr key={index}>
                                                            <td className="storeLogo"><img src={`${product?.path || "/assets/img/user.png"}`} /></td>
                                                            <td>{product?.name}</td>
                                                            <td>{product?.product_count}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>


                </div >
            }
        </>
    )
}

export default StoreAdminDashboard;